import AppDispatcher from "../dispatcher/AppDispatcher"
import AccommodationConstants from "../constants/AccommodationConstants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'


var AccommodationActions = {

  getDetail(accommodation_id){

    fetch(config.get("API2_PATH") + config.get("API2_ACCOMMODATION") + "/" + accommodation_id, {
      method: 'GET',
      headers: {

     }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data != null){
        AppDispatcher.dispatch({
          actionType: AccommodationConstants.GET_DETAIL,
          detail: data
        })
      }
    })
  },




}

export default AccommodationActions
