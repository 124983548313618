import AppDispatcher from "../dispatcher/AppDispatcher"
import BookConstants from "../constants/BookConstants"
import BookStore from "../stores/BookStore"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'


var BookFormActions = {
  create(){
    AppDispatcher.dispatch({
      actionType: BookConstants.CREATE
    })

  },

  update(name,value){


    AppDispatcher.dispatch({
      actionType: BookConstants.UPDATE,
      name: name,
      value:value
    })

  },

  addOption(value){

    AppDispatcher.dispatch({
      actionType: BookConstants.ADD_OPTION,
      value:value
    })

  },




}

export default BookFormActions
