import React from "react"
import config from 'react-global-configuration';

import { Link } from 'react-router-dom'

export default class PrefectureSelection extends React.Component {

  constructor(){
    super()

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  handleChange(e){
    const target = e.target
    this.props.updateForm(target.name,target.value)
  }




  render(){
    const list_objects = []
    list_objects.push(<option value={0} key="pref_0">-</option>)
    for(var i = 0; i < 47; i++){
      list_objects.push(<option value={i+1} key={"pref_" + i}>{config.get("PREF_" + (i+1))}</option>)
    }


    return(
      <div className="m-select_group">
        <select className="m-select m-form" name="prefecture" value={this.props.item.prefecture} onChange={this.handleChange}>
        {list_objects}
        </select>
      </div>
    )
  }
}
