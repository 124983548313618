
const config_default = {
    COMPANY_ID_1:   1,
    COMPANY_ID_2:   1,
    COMPANY_ID_14:   1,
    COMPANY_ID_23:   1,
    COMPANY_ID_24:   1,
    COMPANY_ID_25:   1,

    HP:'https://thehousehayama.com/',
    HP_1:'https://thehousehayama.com/',
    HP_2:'https://www.thehouseonthebeachhayama.com/',
    HP_14:'https://thehousehayama.com/chigasaki',
    HP_23:'https://thehousehayama.com/koajiro',
    HP_24:'https://thehousehayama.com/moroiso',
    HP_26:'https://thehousehayama.com/mvhayama/',
    HP_27:'https://thehousehayama.com/mvjogashima/',

    IK_1:'https://www.ikyu.com/vacation/00050001/',
    IK_2:'https://www.ikyu.com/vacation/00050002/',
    IK_14:'https://www.ikyu.com/vacation/00050737/',
    IK_23:'https://www.ikyu.com/vacation/00051133/',
    IK_24:'https://www.ikyu.com/vacation/00051132/',


    IMG_1:'logo-house.png',
    IMG_2:'logo-onthebeach.png',
    IMG_14:'logo-chigasaki.png',
    IMG_23:'logo_koajiro.svg',
    IMG_24:'logo_moroiso.svg',
    IMG_26:'logo_mvh.png',
    IMG_27:'logo_mvj.png',

    NOTICE_1:"添い寝、食事なしの未就学児は2名まで無料でございます。<br/>3名以降は大人1名と同じ料金となりますので、人数にカウントして 検索くださいませ。<br/>未就学児、わんちゃんの種類と頭数を予約画面の宿への連絡事項欄に 記載をお願いいたします。",
    NOTICE_2:"添い寝、食事なしの未就学児は2名まで無料でございます。<br/>3名以降は大人1名と同じ料金となりますので、人数にカウントして 検索くださいませ。<br/>未就学児、わんちゃんの種類と頭数を予約画面の宿への連絡事項欄に 記載をお願いいたします。",
    NOTICE_14:"添い寝、食事なしの未就学児は2名まで無料でございます。<br/>3名以降は大人1名と同じ料金となりますので、人数にカウントして 検索くださいませ。<br/>未就学児、わんちゃんの種類と頭数を予約画面の宿への連絡事項欄に 記載をお願いいたします。",
    NOTICE_23:"添い寝、食事なしの未就学児は2名まで無料でございます。<br/>3名以降は大人1名と同じ料金となりますので、人数にカウントして 検索くださいませ。<br/>未就学児、わんちゃんの種類と頭数を予約画面の宿への連絡事項欄に 記載をお願いいたします。",
    NOTICE_24:"添い寝、食事なしの未就学児は2名まで無料でございます。<br/>3名以降は大人1名と同じ料金となりますので、人数にカウントして 検索くださいませ。<br/>未就学児、わんちゃんの種類と頭数を予約画面の宿への連絡事項欄に 記載をお願いいたします。",
    NOTICE_26:"添い寝、食事なしの未就学児は2名まで無料でございます。<br/>3名以降は大人1名と同じ料金となりますので、人数にカウントして 検索くださいませ。<br/>未就学児、わんちゃんの種類と頭数を予約画面の宿への連絡事項欄に 記載をお願いいたします。",
    NOTICE_27:"添い寝、食事なしの未就学児は2名まで無料でございます。<br/>3名以降は大人1名と同じ料金となりますので、人数にカウントして 検索くださいませ。最大5名までとなります。<br/>わんちゃんは小型犬までです。3Fのみ犬同伴NG。それ以外はノーリードでお楽しみいただけます。",

    CONFIRM_TEXT_1 :"本施設はホテルや旅館とは異なり、貸別荘形態の施設となります。<br/>ご予約時にお送りするメールに注意事項が記載されておりますので必ずご確認ください。<br/>（室内火の取り扱い禁止、テラスのみ喫煙、迷惑行為の禁止、宿泊者以外のゲスト入場の禁止、違法行為の禁止などとなります）<br/>本施設はホテルや旅館とは異なり、フロントなどの機能はございません。",
    CONFIRM_TEXT_2 :"本施設はホテルや旅館とは異なり、貸別荘形態の施設となります。<br/>ご予約時にお送りするメールに注意事項が記載されておりますので必ずご確認ください。<br/>（室内火の取り扱い禁止、テラスのみ喫煙、迷惑行為の禁止、宿泊者以外のゲスト入場の禁止、違法行為の禁止などとなります）<br/>本施設はホテルや旅館とは異なり、フロントなどの機能はございません。",
    CONFIRM_TEXT_14 :"本施設はホテルや旅館とは異なり、貸別荘形態の施設となります。<br/>ご予約時にお送りするメールに注意事項が記載されておりますので必ずご確認ください。<br/>（室内火の取り扱い禁止、テラスのみ喫煙、迷惑行為の禁止、宿泊者以外のゲスト入場の禁止、違法行為の禁止などとなります）<br/>本施設はホテルや旅館とは異なり、フロントなどの機能はございません。",
    CONFIRM_TEXT_23 :"本施設はホテルや旅館とは異なり、貸別荘形態の施設となります。<br/>ご予約時にお送りするメールに注意事項が記載されておりますので必ずご確認ください。<br/>（室内火の取り扱い禁止、テラスのみ喫煙、迷惑行為の禁止、宿泊者以外のゲスト入場の禁止、違法行為の禁止などとなります）<br/>本施設はホテルや旅館とは異なり、フロントなどの機能はございません。",
    CONFIRM_TEXT_24 :"本施設はホテルや旅館とは異なり、貸別荘形態の施設となります。<br/>ご予約時にお送りするメールに注意事項が記載されておりますので必ずご確認ください。<br/>（室内火の取り扱い禁止、テラスのみ喫煙、迷惑行為の禁止、宿泊者以外のゲスト入場の禁止、違法行為の禁止などとなります）<br/>本施設はホテルや旅館とは異なり、フロントなどの機能はございません。",
    CONFIRM_TEXT_26 :"本施設はホテルや旅館とは異なり、貸別荘形態の施設となります。<br/>ご予約時にお送りするメールに注意事項が記載されておりますので必ずご確認ください。<br/>（室内火の取り扱い禁止、施設敷地内全面禁煙、迷惑行為の禁止、宿泊者以外のゲスト入場の禁止、違法行為の禁止などとなります）<br/>本施設はホテルや旅館とは異なり、フロントなどの機能はございません。",
    CONFIRM_TEXT_27 :"本施設はホテルや旅館とは異なり、貸別荘形態の施設となります。<br/>ご予約時にお送りするメールに注意事項が記載されておりますので必ずご確認ください。<br/>（室内火の取り扱い禁止、施設敷地内全面禁煙、迷惑行為の禁止、宿泊者以外のゲスト入場の禁止、違法行為の禁止などとなります）<br/>本施設はホテルや旅館とは異なり、フロントなどの機能はございません。",

    ROOM_NAME_30 : "Pool and Cave Suite",
    ROOM_NAME_31 : "Shower Living Suite",
    ROOM_NAME_32 : "Bed and Bath room",
    ROOM_NAME_51 : "MAISON D:E VACANCES HAYAMA",
    ROOM_NAME_52 : "THE PENT HOUSE",

    DIRECT:'<p><h2 className="bookdetail">ダイレクトオーダーオプションや近隣おすすめレストランのご案内</h2><a href="https://thehousehayama.com/directorder/" target="_blank">https://thehousehayama.com/directorder/</a> <br/>セルフBBQの機材貸出しや、SUPなどのアクティビティなどの金額も上記に記載させていただいております。ご希望の方はご連絡ください。</p>',


    API_AUTH:                   '/oauth/auth',
    API_PLAN_LIST:              '/api/plan',
    API_PLAN_DETAIL:            '/api/plan/detail',
    API_BOOKED:                 '/api/book',
    API_BOOKED_DETAIL:          '/api/book/detail',
    API_BOOKED_CANCEL:          '/api/book/cancel',
    API_BOOK_CREATE:            '/user/book/create',
    API_ACCOMMODATION:          '/api/accommodation/detail',
    API_PAYMENT_CREATE:         '/user/payment/create',

    API2_CALENDAR:                '/calendars',
    API2_BOOK:                    '/books',
    API2_PLAN:                    '/plans',
    API2_FAQ:                     '/faqs',
    API2_ACCOMMODATION:           '/accommodations',
    API2_KEY:                     'ohk8BephEizea2oo',
    API2_PAYMENT:                 '/payments',
    API2_ID:                      '356a192b7913b04c54574d18c28d46e6395428ab',

    PREF_1 : '北海道',
    PREF_2 : '青森県',
    PREF_3 : '岩手県',
    PREF_4 : '宮城県',
    PREF_5 : '秋田県',
    PREF_6 : '山形県',
    PREF_7 : '福島県',
    PREF_8 : '茨城県',
    PREF_9 : '栃木県',
    PREF_10 : '群馬県',
    PREF_11 : '埼玉県',
    PREF_12 : '千葉県',
    PREF_13 : '東京都',
    PREF_14 : '神奈川県',
    PREF_15 : '新潟県',
    PREF_16 : '富山県',
    PREF_17 : '石川県',
    PREF_18 : '福井県',
    PREF_19 : '山梨県',
    PREF_20 : '長野県',
    PREF_21 : '岐阜県',
    PREF_22 : '静岡県',
    PREF_23 : '愛知県',
    PREF_24 : '三重県',
    PREF_25 : '滋賀県',
    PREF_26 : '京都府',
    PREF_27 : '大阪府',
    PREF_28 : '兵庫県',
    PREF_29 : '奈良県',
    PREF_30 : '和歌山県',
    PREF_31 : '鳥取県',
    PREF_32 : '島根県',
    PREF_33 : '岡山県',
    PREF_34 : '広島県',
    PREF_35 : '山口県',
    PREF_36 : '徳島県',
    PREF_37 : '香川県',
    PREF_38 : '愛媛県',
    PREF_39 : '高知県',
    PREF_40 : '福岡県',
    PREF_41 : '佐賀県',
    PREF_42 : '長崎県',
    PREF_43 : '熊本県',
    PREF_44 : '大分県',
    PREF_45 : '宮崎県',
    PREF_46 : '鹿児島県',
    PREF_47 : '沖縄県',

    WEEK_0 : '日',
    WEEK_1 : '月',
    WEEK_2 : '火',
    WEEK_3 : '水',
    WEEK_4 : '木',
    WEEK_5 : '金',
    WEEK_6 : '土',

    WEEK_CLASS_0 : 'sunday',
    WEEK_CLASS_1 : '',
    WEEK_CLASS_2 : '',
    WEEK_CLASS_3 : '',
    WEEK_CLASS_4 : '',
    WEEK_CLASS_5 : '',
    WEEK_CLASS_6 : 'saturday',

    UNIT_1: "人",
    UNIT_2: "部屋",

    ERROR_REQUIRED:"この項目は必須です",
    ERROR_MAIL:"メールの形式が正しくありません",
    ERROR_MAIL_CONFIRM:"メールアドレスが一致しません",

    STATUS_0 : '未入金',
    STATUS_1 : '振込確認済み',
    STATUS_2 : 'カード支払済',
    STATUS_3 : '当日支払い',
    STATUS_4 : '振込再確認メールを送信しています',
    STATUS_10: '-',

}

const config_dev = {
    API_AUTH_PATH: 'http://localhost:9020',
    API_PATH: 'http://localhost:9030',
    API2_PATH: 'http://localhost:9000',
  //API_PATH: 'http://vrmapitest.cowzow.jp',
    //API_PATH: 'http://vrmapitest.cowzow.jp',
    IMG_PATH: 'http://localhost:3000/img/',
    BASE_URL: 'http://localhost:3000/',
    PAY_JP_KEY: 'pk_test_02e061bf28f85d4814ce48fd',
    SQUARE_APP_ID: 'sq0idp-GJ5GuilE1ykmOv8RLUNGtA',
    SQUARE_LOCATION_ID: 'LNKRPZTNRWDRC',
    PAYMENT_METHOD_SQUARE:3,
};
/*
const config_production = {
  API_AUTH_PATH: 'http://vrmauthtest.cowzow.jp',
  API_PATH: 'http://vrmapitest.cowzow.jp',
  IMG_PATH: 'http://vrmtest.cowzow.jp/img/',
  BASE_URL: 'http://vrmusertest.cowzow.jp'
};
*/

const config_production = {
  API_AUTH_PATH: 'https://vrmauth.cowzow.jp',
  API_PATH: 'https://vrmapi.cowzow.jp',
  API2_PATH: 'https://api.vrm.ninja',
  IMG_PATH: 'https://book.thehousehayama.com/img/',
  BASE_URL: 'https://book.thehousehayama.com/',
  PAY_JP_KEY: 'pk_live_9fe31f5a705dcb5e3bbf5089',
  SQUARE_APP_ID: 'sq0idp-GJ5GuilE1ykmOv8RLUNGtA',
  SQUARE_LOCATION_ID: 'LNKRPZTNRWDRC',
  PAYMENT_METHOD_SQUARE:3,
};


var config = {}

if(process.env.NODE_ENV === "development"){
  config = Object.assign(config_default,config_dev)
}else if(process.env.NODE_ENV === "production"){
  config = Object.assign(config_default,config_production)
}

export default config;
