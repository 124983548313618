import React from "react"

import config from 'react-global-configuration';
import Confirm from './parts/book/Confirm'
import AccommodationActions from '../actions/AccommodationActions';
import RightConfirm from './parts/common/RightConfirm'
import BookFormStore from '../stores/BookFormStore'
import BookStore from '../stores/BookStore'
import styles from '../styles/book.css'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class SuccessApp extends React.Component {

  constructor({match}){
    super()
    this.backToCalendar = this.backToCalendar.bind(this)
    this.goToCard = this.goToCard.bind(this)
    this.state = {
      usersecret:null,
      group_id:null,
      form:null
    }
  }

  componentDidMount(){
    //AccommodationActions.getDetail(this.state.accommodation_id)
    this.setState({
      usersecret:BookStore.getUserSecret(),
      group_id:BookStore.getGroupId(),
      form:BookFormStore.getDetail()
    })
  }

  componentWillUnmount(){
    //AuthStore.removeChangeListener(this._onChange.bind(this));
  }

  backToCalendar(){
    if(this.state.form && this.state.form.accommodation_id){
      window.location = "/calendar/" + this.state.form.accommodation_id
    }else{
      this.backToHp()
    }
  }
  goToCard(){
    window.location = config.get("BASE_URL") + "book/detail/" + this.state.usersecret
  }
  backToHp(){
      window.location = config.get("HP")
  }


  render(){
    return(
      <div id="book">
        <section className="left-pannel">
          <h1>ご予約完了</h1>
          予約が完了いたしました。ありがとうございました。
          <p>ご予約内容はメールにて送信しておりますので、ご確認ください。</p>
          <p>下記のURLから予約の確認・クレジットカードでのお支払いおよびキャンセルが可能です。</p>
          <p>
          <a href={config.get("BASE_URL") + "book/detail/" + this.state.usersecret  }>
            {config.get("BASE_URL")}book/detail/{this.state.usersecret}
          </a>


  
          </p>
                    <div className="button-box">
                      <button className="huge" onClick={this.goToCard}>クレジットカード払いへ進む</button>
                      <p>VISA / MASTER CARD がご利用いただけます。</p>
                    </div>
                    <div className="button-box">
                      <button className="white big" onClick={this.backToCalendar}>予約サイトへ戻る</button>
                      <button className="huge white" onClick={this.backToHp}> THE HOUSE公式サイトへ戻る</button>
                    </div>
        </section>




      </div>

    )
  }
}
