import AppDispatcher from "../dispatcher/AppDispatcher"
import PlanConstants from "../constants/PlanConstants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import { format } from 'date-fns'

var PlanActions = {

  getList(accommodation_id, start_date = null, end_date = null){

    let params = new URLSearchParams();
    if(start_date){
      params.set('start_date', format(start_date, 'yyyy-MM-dd'));

    }

    if(end_date){
      params.set('end_date', format(end_date, 'yyyy-MM-dd'));

    }

    fetch(config.get("API2_PATH") + config.get("API2_ACCOMMODATION") +"/" +accommodation_id + "/plans?" + params.toString(), {
      method: 'GET'
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {
    
      if(data != null ){
        AppDispatcher.dispatch({
          actionType: PlanConstants.GET_PLAN_LIST,
          list: data
        })
      }
    })
  },


  getDetail(plan_id){
    if(plan_id && plan_id > 0){
      fetch(config.get("API2_PATH") + config.get("API2_PLAN") + "/" + plan_id, {
        method: 'GET',
        headers: {
         'Content-Type': 'application/json'
       }
      }).then(function(response) {
        if(response.status == 200){
          return response.json()
        }else if(response.status == 401){
          return null
        }else{
          return null
        }
      }).then(function(data) {

        if(data != null ){
          AppDispatcher.dispatch({
            actionType: PlanConstants.GET_PLAN_DETAIL,
            item: data
          })
        }
      })
    }

  },




}

export default PlanActions
