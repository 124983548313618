import React from "react"
import PlanActions from '../actions/PlanActions';
import BookActions from '../actions/BookActions';
import AccommodationActions from '../actions/AccommodationActions';
import Header from './parts/common/Header'
import RoomList from './parts/plan/RoomList'
import '../styles/plan.css'
import { addDays,subDays,isBefore,isSameDay } from 'date-fns'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class PlanApp extends React.Component {

  constructor({match}){
    super()
  
    this.back = this.back.bind(this)
    this.state = {
      accommodation_id:match.params.accommodation_id,
      year:match.params.year,
      month:match.params.month,
      day:match.params.day,
      person_number:match.params.person_number,
      days:match.params.days
    }
  }

  componentDidMount(){
    var target_date = new Date(this.state.year, this.state.month -1 , this.state.day)
    BookActions.getList(this.state.accommodation_id,target_date,addDays(target_date, this.state.days - 1))
    //PlanActions.getList(this.state.accommodation_id, target_date.getTime(), target_date.getTime())
    AccommodationActions.getDetail(this.state.accommodation_id)
  }

  componentWillUnmount(){
    //AuthStore.removeChangeListener(this._onChange.bind(this));
  }



  back(){
    this.props.history.goBack()
  }

  render(){
    return(
      <div>
        <Header accommodation_id={this.state.accommodation_id}/>
        <section id="plan">
        <h2>{this.state.year}年{this.state.month}月{this.state.day}日から{this.state.days}泊({this.state.person_number}名)で利用可能な部屋タイプとプラン</h2>
        <RoomList accommodation_id={this.state.accommodation_id} person_number={this.state.person_number} target_date={ new Date(this.state.year, this.state.month -1 , this.state.day)} days={this.state.days}/>

        <div className="button-box">
          <button className="white big" onClick={this.back}>カレンダーへ戻る</button>
        </div>

        </section>
      </div>

    )
  }
}
