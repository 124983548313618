import keyMirror from "keymirror"

export default keyMirror({
  ///Messagections
  GET_BOOKED_LIST:null,
  GET_BOOKED_DETAIL:null,
  UPDATE:null,
  CREATE:null,
  CREATED:null,
  CANCEL:null,
  ADD_OPTION:null,
  ERROR_:null
})
