import React from "react"

import config from 'react-global-configuration';
import Confirm from './parts/book/Confirm'
import AccommodationActions from '../actions/AccommodationActions';
import RightConfirm from './parts/common/RightConfirm'
import BookFormStore from '../stores/BookFormStore'
import BookStore from '../stores/BookStore'
import styles from '../styles/book.css'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class BookErrorApp extends React.Component {

  constructor({match}){
    super()
    this.backToCalendar = this.backToCalendar.bind(this)
    this.state = {

      form:null
    }
  }

  componentDidMount(){
  //  AccommodationActions.getDetail(this.state.form.accommodation_id)
    this.setState({

      form:BookFormStore.getDetail()
    })
  }

  componentWillUnmount(){
    //AuthStore.removeChangeListener(this._onChange.bind(this));
  }

  backToCalendar(){
    if(this.state.form && this.state.form.accommodation_id){
      window.location = "/calendar/" + this.state.form.accommodation_id
    }else{
      this.backToHp()
    }
  }

  backToHp(){
    console.log(config.get("HP"))
      window.location = config.get("HP")
  }


  render(){
    return(
      <div id="book">
        <section class="left-pannel">
          <h1>エラーが発生しました</h1>
          現在ご指定いただいた日程では予約を受け付けることができませんでした。

          <div className="button-box">
            <button className="white big" onClick={this.backToCalendar}>カレンダーへ戻る</button>
            <button className="big" onClick={this.backToHp}>HPへ戻る</button>
          </div>

        </section>
        <section className="right-pannel">
          <RightConfirm updateForm={this.updateForm} accommodation_id={this.state.accommodation_id} plan_id={this.state.plan_id}/>
        </section>


      </div>

    )
  }
}
