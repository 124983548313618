import React, { useState,useEffect ,useCallback} from 'react';
import config from 'react-global-configuration';
import PlanItem from "./PlanItem"



function RoomItem(props) {

  const [plans, setPlans] = useState([])

  //const stableBookStoreChangeHandler = useCallback(bookStoreChangeHandler ,[])

  useEffect(() => {
    setPlans(props.room.plans.map(
      plan => {
        return <PlanItem plan={plan} accommodation_id={props.accommodation_id} room_id={props.room.id} target_date={props.target_date} days={props.days} person_number={props.person_number} />
      }
    ))
  },[]);

  return (
    <div className="room-list"><div className="room-name">{props.room ? props.room.name : null}</div><div className="plans">{plans}</div></div>
  )
}
export default RoomItem
