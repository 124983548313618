import { Record } from 'immutable'

const Plan = Record({
  id:null,
  name: null,
  close_date_before: null,
  close_time_before:null,
  price:null,
  additional_price:null,
  start_at:null,
  end_at:null,
  max_num:null,
  check_in:null,
  check_out:null,
  description:null,
  schedules:null,
  room:null,
  payment_methods:null,
  unit:null,

});
export default Plan;
