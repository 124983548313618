import React, { useState, useEffect,useCallback } from 'react'
import CardStore from "../../../stores/CardStore"
import SquareActions from "../../../actions/SquareActions"
import "../../../styles/square.css"



function CardSquare(props:PropsValue) {

  const stableCardErrorHandler = useCallback(cardErrorHandler,[])
  const stableCardChangeHandler = useCallback(cardChangeHandler, [])
  const [btn ,setBtn] = useState(null)
  const [message, setMessage] = useState(null)
  useEffect(() => {
    CardStore.addChangeListener(stableCardChangeHandler)
    CardStore.addChangeListener(stableCardErrorHandler,"error_pay")
    SquareActions.init()

    return function cleanup() {
      CardStore.removeChangeListener(stableCardChangeHandler)
      CardStore.removeChangeListener(stableCardErrorHandler,"error_pay")
    };
  },[stableCardErrorHandler,stableCardChangeHandler,stableCardErrorHandler]);

  function cardErrorHandler(){
    setBtn(<button id="card-button" type="button" onClick={payExe}>{props.btn_text}</button>)
    setMessage(<div className="message3 cent">カードエラーが発生しました。カードをご確認ください。</div>)
  }

  function payExe(){
    setBtn(null )
    setMessage("送信中...")
    SquareActions.payBySquare(CardStore.getCard(), props.book_id, props.amount, "false")
    if(props.close){
      props.close()
    }
  }
  function cardChangeHandler(){
    const card = CardStore.getCard()
    card.attach('#card')
    setBtn(<button id="card-button" type="button" onClick={payExe}>{props.btn_text}</button>)
  }
  
  return(
    <div className="card">
       
      <div id="card"></div>
      {message}
      <div className="button-box">{btn}</div>
    </div>
  )
}

export default CardSquare




/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/


/** 

export default class Card extends React.Component {

  constructor(){
    super()
    this.getToken = this.getToken.bind(this)
    this.onChange = this.onChange.bind(this)
    this.CardStoreChangeHandler = this.CardStoreChangeHandler.bind(this)
    this.TokenChangeHandler = this.TokenChangeHandler.bind(this)
    this.createPayment = this.createPayment.bind(this)
    this.PaymentChangeHandler = this.PaymentChangeHandler.bind(this)
    this.PayErrorHandler = this.PayErrorHandler.bind(this)
    this.state = {
      item:new Card(),
      token:null,
      is_process:false,
      is_paid:false
    }
  }

  componentDidMount(){
      CardStore.addChangeListener(this.CardStoreChangeHandler)
      CardStore.addChangeListener(this.TokenChangeHandler,"change_token")
      CardStore.addChangeListener(this.PaymentChangeHandler,"change_payment")
      CardStore.addChangeListener(this.PayErrorHandler,"error_pay")
  }

  componentWillUnmount(){
    CardStore.removeChangeListener(this.CardStoreChangeHandler)
    CardStore.removeChangeListener(this.TokenChangeHandler,"change_token")
    CardStore.removeChangeListener(this.PaymentChangeHandler,"change_payment")
    CardStore.removeChangeListener(this.PayErrorHandler,"error_pay")
  }

  onChange(e){
    const target = e.target
    this.props.updateForm(target.name, target.value)
  }


  CardStoreChangeHandler(){
    this.setState(
      {
        item:CardStore.getDetail()
      }
    )
  }

  TokenChangeHandler(){
    console.log(CardStore.getToken())
    this.setState(
      {
        token:CardStore.getToken()
      }
    )
  }

  PaymentChangeHandler(){
    this.setState({
      is_paid:true,
      is_process:true
    })
  }

  PayErrorHandler(){
    this.setState({
      is_process:false,
      token:null
    })
  }

  getToken(){
    this.setState({
      message:null
    })
    this.props.getToken(CardStore.getDetail())
  }

  createPayment(){
    this.setState({
      is_process:true,
      message:null
    })
    this.props.createPayment()
  }


  render(){
    if(!this.state.item ){
      return null
    }

    var confirmBTN = null
    var payBTN = null
    var formHTML = null

    if(!this.state.token){
    formHTML =  <table>
        <tbody>
          <tr>
            <th>カード番号</th>
            <td><input type="text" value={this.state.item.number} onChange={this.onChange} name="number"/></td>
            <th>有効期限</th>
            <td><input type="text" className="sml" value={this.state.item.exp_month} onChange={this.onChange} name="exp_month" placeholder="月"/> / <input className="sml"  type="text" value={this.state.item.exp_year} onChange={this.onChange} name="exp_year" placeholder="年" /></td>
          </tr>
            <tr>
              <th>CVC番号<br/>(3桁または4桁)</th>
              <td><input type="password" value={this.state.item.cvc} onChange={this.onChange} name="cvc" /></td>
              <td colspan="3"></td>
            </tr>

        </tbody>
      </table>
      confirmBTN = <button className="big" onClick={this.getToken}>カード情報の確認</button>
    }else{
      if(!this.state.is_process){
        payBTN = <p className="message">カード情報が確認できました。まだ支払いは完了していません。下記のボタンを押して確定させてください。<br/><button className="big" onClick={this.createPayment}>支払いを確定</button></p>
      }
    }

    return(
      <div className="card">
        <p>VISA / MASTER CARD がご利用いただけます。</p>
        {formHTML}


        <div className="button-box">
          {confirmBTN}
          {payBTN}
        </div>

      </div>

    )
  }
}
*/