import AppDispatcher from "../dispatcher/AppDispatcher"
import { Record, List } from 'immutable'
import { EventEmitter } from "events"
import AccommodationConstants from "../constants/AccommodationConstants"
import assign from "object-assign"


var CHANGE_EVENT = "change" // chenge evnetを定数にする

var _list = []



const setList= (list) =>{
  _list = list
}

const FaqStore = assign({},EventEmitter.prototype,{


  getList(){
    return _list
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case AccommodationConstants.GET_FAQ_LIST:
      setList(action.list);
      FaqStore.emitChange()
      break;


    default:
      // no
  }
})
export default FaqStore
