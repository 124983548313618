import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import CalendarApp from './components/CalendarApp'
import PlanApp from './components/PlanApp'
import BookApp from './components/BookApp'
import ConfirmApp from './components/ConfirmApp'
import SuccessApp from './components/SuccessApp'
import BookDetailApp from './components/BookDetailApp'
import CancelApp from './components/CancelApp'
import FaqApp  from './components/FaqApp'
import OptionApp  from './components/OptionApp'
import BookErrorApp  from './components/BookErrorApp'

config.set(configuration);

class App extends React.Component{


render(){
return(
     <div>
       <Switch>
        <Route exact path='/book/success' component={SuccessApp} />
        <Route exact path='/book/error' component={BookErrorApp} />
        <Route exact path='/cancel' component={CancelApp} />
        <Route exact path='/calendar/:accommodation_id' component={CalendarApp} />
        <Route exact path='/calendar/:year/:month' component = {CalendarApp} />
        <Route exact path='/faq/:accommodation_id' component = {FaqApp} />
        <Route exact path='/faq/:accommodation_id/:room_id' component = {FaqApp} />
       
        <Route exact path='/plan/:accommodation_id/:year/:month/:day/:person_number/:days' component = {PlanApp} />
        <Route exact path='/confirm/:accommodation_id/:plan_id/:year/:month/:day' component = {ConfirmApp} />
        <Route exact path='/book/:accommodation_id/:plan_id/:room_id/:year/:month/:day/:person_number/:days' component = {BookApp} />
        <Route exact path='/book/detail/:usersecret' component = {BookDetailApp} />
        <Route exact path='/book/options/:usersecret' component = {OptionApp} />
        </Switch>
      </div>
)}}

ReactDOM.render(
  (<BrowserRouter><App /></BrowserRouter>),
  document.getElementById('root')
)
