import AppDispatcher from "../dispatcher/AppDispatcher"
import CardConstants from "../constants/CardConstants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import BookActions from "./BookActions"


var CardActions = {

  

  update(name,value){

    AppDispatcher.dispatch({
      actionType: CardConstants.UPDATE_CARD,
      name: name,
      value:value
    })

  },

  getAvailableCard(payjp){

   
    payjp.retrieveAvailability( ).then((response) => {
      if (response.error) {
        // handle error
      } else {
        console.log(response)
        // {card_types_supported: ["Visa", "MasterCard", "JCB", "American Express", "Diners Club", "Discover"], livemode: true}
      }
    });

  },
  createToken(payjp, cardElement){



    payjp.createToken(cardElement).then((response) => {
      if (response.error) {
        AppDispatcher.dispatch({
          actionType: CardConstants.ERROR_CARD,
          code: 1
        })
      } else {
        AppDispatcher.dispatch({
          actionType: CardConstants.UPDATE_TOKEN,
          token:response.id
        })
      }
    })

    
  },
  
  paymentExe(pay_token,book_id,amount, is_option = null){
    fetch(config.get("API2_PATH") + config.get("API2_BOOK") + "/" + book_id + config.get("API2_PAYMENT"), {
      method: 'POST',
      headers: {
       'Content-Type': 'application/json',
       'key': config.get("API2_KEY"),
       'id': config.get("API2_ID")
     },
     body: JSON.stringify({
      payment_id:2,
      amount:amount,
      card_token:pay_token,
      is_option:is_option
    })
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return response.status
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data != null){
        if(is_option){
          BookActions.getDetail(book_id)
        }
        AppDispatcher.dispatch({
          actionType: CardConstants.PAYMENT_DONE,
        })
      }else{

        AppDispatcher.dispatch({
          actionType: CardConstants.ERROR_PAY,
          code:data
        })
      }
    })


  }
  /*
  update(name,value){


    AppDispatcher.dispatch({
      actionType: BookConstants.UPDATE,
      name: name,
      value:value
    })

  },

  addOption(value){

    AppDispatcher.dispatch({
      actionType: BookConstants.ADD_OPTION,
      value:value
    })

  },
  */




}

export default CardActions
