import React,{useState,useEffect,useCallback} from 'react'
import {  useParams} from 'react-router-dom';
import config from 'react-global-configuration';
import FaqActions from '../actions/FaqActions';
import FaqStore from "../stores/FaqStore"
import  '../styles/faq.css'
import  '../styles/static.css'
function FaqApp(props:PropsValue) {
  const { accommodation_id } = useParams();
  const { room_id } = useParams();
  const [list, setList] = useState([])
  const [questions, setQuestions] = useState([])
  const stableFaqChangeHandler = useCallback(faqChangeHandler,[])


  useEffect(() => {

    FaqStore.addChangeListener(stableFaqChangeHandler)
    FaqActions.getList(accommodation_id,room_id)
    return function cleanup() {
      FaqStore.removeChangeListener(stableFaqChangeHandler)
    };

  },[stableFaqChangeHandler,props]);


  function faqChangeHandler(){
    setList(FaqStore.getList().map(item => {
      return <div className="faqlist-item" id={"qa_" + item.id}>
        <div className="question"><div className="label">Q</div><div className="text">{item.question}</div></div>
        <div className="answer"><div className="label">A</div><div className="text" dangerouslySetInnerHTML={{ __html:item.answer.replace(/\r?\n/g, '<br>')}} /></div>
      </div>
    }))

    setQuestions(FaqStore.getList().map(item => {
      return <div className="questionlist-item">
        <div className="label"></div><div className="text"><a href={"#qa_" + item.id}>{item.question}</a></div>
      </div>
    }))
  }

  return(
    <div className="static">
      <header>
        <h1>FAQ {room_id ? "(" + config.get("ROOM_NAME_" + room_id) + ")" : null}</h1>
        <div className="image" ><a href={config.get("HP_" + accommodation_id)}><img src={"/img/" + config.get("IMG_" + accommodation_id)} alt="logo" /></a></div>
      </header>
      <div className="faqlist question-list">{questions}</div>
      <div className="faqlist main-content">{list}</div>
    </div>

  )
}

export default FaqApp