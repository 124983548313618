import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import CardConstants from "../constants/CardConstants"
import assign from "object-assign"
import Card from "../vo/Card"


var CHANGE_EVENT = "change" // chenge evnetを定数にする
var TOKEN_EVENT = "change_token" // chenge evnetを定数にする
var PAYMENT_DONE = "change_payment"
var ERROR_CARD = "error_card"
var ERROR_PAY = "error_pay"



var _card = new Card()
var _token = null
var _erro_code = null

/*
*  ここから処理本体を書き始める
*/


var setDetail= (name,value) =>{
  _card =  _card.set(name,value)

}

var setToken = (token) =>{
  _token = token
}

const setCard = (card) => {
  _card = card
}


var setErrorCode = (code) => {
  _erro_code = code
}

var CardStore = assign({},EventEmitter.prototype,{


  getCard(){
    return _card
  },
  
  getDetail(){
    return _card
  },

  getToken(){
    return _token
  },

  getErrorCode(){
    return _erro_code
  },



  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case CardConstants.UPDATE_CARD:
      setDetail(action.name,action.value);
      CardStore.emitChange()
      break;
    case CardConstants.UPDATE_TOKEN:
      setToken(action.token);
      CardStore.emitChange(TOKEN_EVENT)
      break;
    case CardConstants.PAYMENT_DONE:
        CardStore.emitChange(PAYMENT_DONE)
        break;
    case CardConstants.ERROR_CARD:
        setErrorCode(action.code)
        CardStore.emitChange(ERROR_CARD)
        break;
    case CardConstants.ERROR_PAY:
        setErrorCode(action.code)
        CardStore.emitChange(ERROR_PAY)
        break;
    case CardConstants.ERROR_SQUARE:
          setErrorCode(action.code)
          CardStore.emitChange(ERROR_CARD)
          break;
    case CardConstants.SQUARE_CARD:
          setCard(action.card)
          CardStore.emitChange()
          break;
    default:


      // no
  }
})
export default CardStore
