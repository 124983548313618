import React, { useState,useEffect,useCallback } from 'react';




function OptionItem(props) {
  const [unit, setUnit] = useState(0)

  const stableSetUnit = useCallback(setUnit,[])
  useEffect(() => {

  },[]);


  function createOption(){
    props.createOption(props.item.id, unit, 1)
  }
  

  return (
   
    <div className="option-list-item">
      <div className="w200 bold title">{props.item.name}</div>
      <div className="free small" >{props.item.description}</div>
      <div className="w50 row">
      <input type="text" className="sml" onChange={(e) => stableSetUnit(e.target.value)} name="unit" placeholder="0" value={unit}/> 
      <span className="small">&nbsp;{props.item.unit}</span>
      </div>
      <div className="w100"><button className="small create" onClick={() =>createOption(props.item.id)}>追加</button></div>
    </div>
  )
}
export default OptionItem
