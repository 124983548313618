import React from "react"
import config from 'react-global-configuration';
import style from '../../../styles/header.css'
import BookFormStore from "../../../stores/BookFormStore"
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class StayNumSelector extends React.Component {

  constructor(){
    super()

    this.BookFormStoreChangeHandler = this.BookFormStoreChangeHandler.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.state = {
      book_form:null
    }
  }

  componentDidMount(){
    BookFormStore.addChangeListener(this.BookFormStoreChangeHandler)

  }

  componentWillUnmount(){
    BookFormStore.removeChangeListener(this.BookFormStoreChangeHandler)
  }

  BookFormStoreChangeHandler(){
    this.setState({
      book_form:BookFormStore.getDetail()
    })
  }
  changeHandler(e){
    const target = e.target
    this.props.updateBookForm("days",target.value)
  }

  render(){
    if(! this.state.book_form) return null

    var option_list = []
    for(var i = 0; i < 6; i++){
       option_list.push(<option value={i+1} key={"select_" + i}>{i+1}</option>)
    }

    return(
      <div className="book-num-select">
        <div className="m-select_group">
          <select className="m-select m-form" name="days" value={this.state.book_form.days} onChange={this.changeHandler}>
          {option_list}
          </select>
        </div>
      </div>

    )
  }
}
