import React from "react"

import PlanActions from '../actions/PlanActions';
import BookActions from '../actions/BookActions';
import BookFormActions from '../actions/BookFormActions';
import AccommodationActions from '../actions/AccommodationActions';
import PlanStore from '../stores/PlanStore'

import RightConfirm from './parts/common/RightConfirm'
import Book from './parts/book/Book'
import styles from '../styles/book.css'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ConfirmApp extends React.Component {

  constructor({match}){
    super()
    this.back = this.back.bind(this)
    this.confirm = this.confirm.bind(this)
    this.PlanStoreChangeHandler = this.PlanStoreChangeHandler.bind(this)
    this.updateOptionForm = this.updateOptionForm.bind(this)

    this.state = {
      plan:null,
      price:null,
      accommodation_id:match.params.accommodation_id,
      year:match.params.year,
      month:match.params.month,
      day:match.params.day,
      plan_id:match.params.plan_id,
      room_id:match.params.room_id,
      person_number:match.params.person_number,
      days:match.params.days
    }

  }

  componentDidMount(){
    PlanStore.addChangeListener(this.PlanStoreChangeHandler)
    var target_date = new Date(this.state.year, this.state.month - 1, this.state.day)
    PlanActions.getDetail(this.state.plan_id)
    var check_out = new Date(this.state.year, this.state.month - 1, this.state.day)

    check_out.setDate(check_out.getDate() + Number(this.state.days));

    AccommodationActions.getDetail(this.state.accommodation_id)
    BookFormActions.update("check_in_date",target_date)
    BookFormActions.update("check_out_date",check_out)
    BookFormActions.update("person_number",this.state.person_number)
    BookFormActions.update("accommodation_id",this.state.accommodation_id)
    BookFormActions.update("plan_id",this.state.plan_id)
    BookFormActions.update("days",this.state.days)
    BookFormActions.update("room_id",this.state.room_id)


  }

  componentWillUnmount(){
    PlanStore.removeChangeListener(this.PlanStoreChangeHandler)

    //AuthStore.removeChangeListener(this._onChange.bind(this));
  }

  PlanStoreChangeHandler(){
    var item = PlanStore.getDetail()
    var price = item.price +(item.additional_price * (this.state.person_number - 1) )

    for(var i = 0; i < item.schedules.length; i++){
        if(item.schedules[i].unit == this.state.person_number){
          price = item.schedules[i].price
        }
    }

    this.setState({
      plan:item,
      price:price
    })

  }
  /*
  setPrice(price){
    BookFormActions.update("price",price)
  }*/
  updateForm(name,value){
    BookFormActions.update(name,value)
  }
  updateOptionForm(value){
    BookFormActions.addOption(value)
  }
  confirm(){
    //setUpRoomID
    this.props.history.push("/confirm/" + this.state.accommodation_id + "/" + this.state.plan_id + "/" + this.state.year + "/" + this.state.month + "/" + this.state.day)
  }
  back(){
    this.props.history.goBack()
  }


  render(){
    return(
      <div id="book">
        <section className="left-pannel">
          <h1>予約情報</h1>
            <Book updateForm={this.updateForm} confirm={this.confirm} updateOptionForm={this.updateOptionForm} back={this.back} key="book"/>

        </section>
        <section className="right-pannel">
          <RightConfirm updateForm={this.updateForm} accommodation_id={this.state.accommodation_id} plan_id={this.state.plan_id}/>
        </section>

      </div>

    )
  }
}
