import React from "react"
import config from 'react-global-configuration';
import CalendarStore from "../../../stores/CalendarStore"
//import AuthActions from '../actions/AuthActions';

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class CalendarSelector extends React.Component {

  constructor(){
    super()
    this.CalendarStoreChangeHandler = this.CalendarStoreChangeHandler.bind(this)
    this.previousMonth = this.previousMonth.bind(this)
    this.nextMonth = this.nextMonth.bind(this)
    this.state = {
      year:null,
      month:null
    }
  }

  componentDidMount(){
    CalendarStore.addChangeListener(this.CalendarStoreChangeHandler)
  }

  componentWillUnmount(){
    CalendarStore.removeChangeListener(this.CalendarStoreChangeHandler)
  }

  CalendarStoreChangeHandler(){

    this.setState({
      year:CalendarStore.getCurrentYear(),
      month:CalendarStore.getCurrentMonth()
    })
  }

  previousMonth(){
    var target_month = this.state.month - 1
    var target_year = this.state.year
    if(target_month == 0){
      target_year -= 1
      target_month = 12
    }
    this.props.updateCurrentMonth(target_year,target_month)

  }
  nextMonth(){
    var target_month = this.state.month + 1
    var target_year = this.state.year
    if(target_month == 13){
      target_year += 1
      target_month = 1
    }
    this.props.updateCurrentMonth(target_year,target_month)
  }

  render(){


    return(
      <div className="month-selector">
        <div className="navigator" onClick={this.previousMonth}><i className="fa fa-chevron-left"></i>&nbsp;前の月</div>
        <div>{this.state.year}年{this.state.month}月</div>
        <div className="navigator" onClick={this.nextMonth}>次の月&nbsp;<i className="fa fa-chevron-right"></i></div>
      </div>
    )
  }
}
