import { Record } from 'immutable'

const BookForm = Record({
  id:null,
  company_id:null,
  accommodation_id: null,
  plan_id: null,
  room_id:null,
  target_date:null,
  name:null,
  price:0,
  address:null,
  tel:null,
  mail:null,
  mail_confirm:null,
  prefecture:null,
  person_number:null,
  comment:null,
  check_in_date:null,
  check_out_date:null,
  room:null,
  plan_name:null,
  room_name:null,
  days:1,
  price:null,
  status:null,
  payment_limit_date:null,
  options:[],
  payments:[],
  payment_methods:[],
  delete_flg:null,
  uid:null,
});
export default BookForm;
