import React from "react"

import PlanActions from '../actions/PlanActions';
import BookActions from '../actions/BookActions';
import BookFormActions from '../actions/BookFormActions';
import AccommodationActions from '../actions/AccommodationActions';
import RightConfirm from './parts/common/RightConfirm'
import BookFormStore from '../stores/BookFormStore'
import BookStore from '../stores/BookStore'

import Confirm from './parts/book/Confirm'
import  '../styles/book.css'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ConfirmApp extends React.Component {

  constructor({match}){
    super()
    this.back = this.back.bind(this)
    this.confirm = this.confirm.bind(this)
    this.BookFormStoreChangeHandler = this.BookFormStoreChangeHandler.bind(this)
    this.createdHandler = this.createdHandler.bind(this)
    this.createErrorHandler = this.createErrorHandler.bind(this)

    this.state = {
      accommodation_id:match.params.accommodation_id,
      year:match.params.year,
      month:match.params.month,
      day:match.params.day,
      plan_id:match.params.plan_id,
      form:null,
      plan:null
    }
  }

  componentDidMount(){
    var target_date = new Date(this.state.year, this.state.month - 1, this.state.day)

    PlanActions.getDetail(this.state.plan_id)
    AccommodationActions.getDetail(this.state.accommodation_id)
    BookFormStore.addChangeListener(this.BookFormStoreChangeHandler)
    BookStore.addChangeListener(this.createdHandler,"created")
    BookStore.addChangeListener(this.createErrorHandler,"error")

    this.setState({
      form:BookFormStore.getDetail()
    })
  }

  componentWillUnmount(){

    BookFormStore.removeChangeListener(this.BookFormStoreChangeHandler)
    BookStore.removeChangeListener(this.createdHandler,"created")
    BookStore.removeChangeListener(this.createErrorHandler,"error")
  }

  updateForm(name,value){
    BookFormActions.update(name,value)
  }

  createdHandler(){
    this.props.history.push("/book/success")
  }

  createErrorHandler(){
    this.props.history.push("/book/error")
  }


  back(){
    this.props.history.goBack()
  }
  confirm(){
/*
    var options = this.state.item.bookOption
    var option_price = 0;

    if(options){
          var booked_option = null
          var unitnum = 0
          var price_num = 0
          options.map((item) =>{
          for(var i = 0; i < this.state.form.option.length; i++){

            if(item.id == this.state.form.option[i].id ){
              booked_option = this.state.form.option[i]
              unitnum = booked_option.unit
              price_num = item.price * unitnum * this.state.form.days
              option_price += price_num
            }
          }
        });
    }

    console.log(option_price)
    */
    //
    //console.log(this.state.form)
    BookActions.create(this.state.form)
    //this.props.history.push("/success")
  }

  BookFormStoreChangeHandler(){


  }


  render(){

    return(
      <div id="book">
        <section class="left-pannel">
          <h1>予約情報の確認</h1>
          <Confirm updateForm={this.updateForm} confirm={this.confirm} back={this.back}/>

        </section>
        <section className="right-pannel">
          <RightConfirm updateForm={this.updateForm} accommodation_id={this.state.accommodation_id} plan_id={this.state.plan_id}/>
        </section>

      </div>

    )
  }
}
