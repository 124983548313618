import React, { useState,useEffect ,useCallback} from 'react';
import config from 'react-global-configuration';
import CalendarStore from "../../../stores/CalendarStore"
import BookStore from "../../../stores/BookStore"
import RoomItem from "./RoomItem"


function RoomList(props) {

  const [room_list, setRoomList] = useState(null)

  const stableBookStoreChangeHandler = useCallback(bookStoreChangeHandler ,[])

  useEffect(() => {
    BookStore.addChangeListener(stableBookStoreChangeHandler)
    return () => {
      BookStore.removeChangeListener(stableBookStoreChangeHandler)
    };
  },[stableBookStoreChangeHandler]);


  function bookStoreChangeHandler(){

    setRoomList(
      BookStore.getList()[0].room.map( room => {
        const max_num = getMaxPersson(room)
        if(max_num >= props.person_number){
          return <RoomItem accommodation_id={props.accommodation_id} room={room} target_date={props.target_date} person_number={props.person_number} days={props.days}/>
        }else{
          return null
        }

      })
    )
  }

  function getMaxPersson(room){
    var max_num = 0

    for(var i in room.plans){
      if(room.plans[i].max_num > max_num){
        max_num = room.plans[i].max_num
      }
    }
    return max_num
  }

  return (
    <div >{room_list}</div>
  )
}


export default RoomList
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}


export default class PlanList extends React.Component {

  constructor(){
    super()
    this.BookStoreChangeHandler = this.BookStoreChangeHandler.bind(this)
    this.state = {
      books:null
    }
  }

  componentDidMount(){
    BookStore.addChangeListener(this.BookStoreChangeHandler)
  }

  componentWillUnmount(){
    BookStore.removeChangeListener(this.BookStoreChangeHandler)
  }



  BookStoreChangeHandler(){
    this.setState({
      books:BookStore.getList()
    })
  }


  render(){

    if( ! this.state.books || this.state.books.length < 1) return null
    const plan_list = this.state.books[0].room.map((item) =>{
      //console.log(item)
      const max_stay_num = item.plans.map(p => p.max_num).reduce((a,b)=>a>b?a:b)
      if(max_stay_num >= this.props.person_number){
        return <PlanItem key={item.id} item={item}
        moveTo={this.props.moveTo}
        unit = {this.props.person_number}
        />
      }else{
        return null
      }

    });
    return(
      <table className="plan-list">
        <tbody>
          {plan_list}
        </tbody>
      </table>

    )
  }
}
*/
