import React from "react"
import config from 'react-global-configuration';
import style from '../../../styles/header.css'
import AccommodationStore from "../../../stores/AccommodationStore"
import BookFormStore from "../../../stores/BookFormStore"
import PlanStore from "../../../stores/PlanStore"
import Common from "../../../utils/Common"
import { format,addDays } from 'date-fns'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class RightConfirm extends React.Component {

  constructor(){
    super()

    this.BookFormStoreChangeHandler = this.BookFormStoreChangeHandler.bind(this)
    this.AccommodationStoreChangeHandler = this.AccommodationStoreChangeHandler.bind(this)
    this.PlanStoreChangeHandler = this.PlanStoreChangeHandler.bind(this)
    this.state = {
      form:null,
      plan:null,
      item:null,
      price:0,
    }
  }

  componentDidMount(){
    AccommodationStore.addChangeListener(this.AccommodationStoreChangeHandler)
    BookFormStore.addChangeListener(this.BookFormStoreChangeHandler)
    PlanStore.addChangeListener(this.PlanStoreChangeHandler)
    this.setState({
      form:BookFormStore.getDetail()
    })
  }

  componentWillUnmount(){
    AccommodationStore.removeChangeListener(this.AccommodationStoreChangeHandler)
    BookFormStore.removeChangeListener(this.BookFormStoreChangeHandler)
    PlanStore.removeChangeListener(this.PlanStoreChangeHandler)
  }

  AccommodationStoreChangeHandler(){
    this.setState({
      item:AccommodationStore.getDetail(this.props.accommodation_id)
    })
  }




  BookFormStoreChangeHandler(){
    this.setState({
      form:BookFormStore.getDetail()
    })
  }

  PlanStoreChangeHandler(){
    var item = PlanStore.getDetail()
    var price = 0
    var target_day = this.state.form.check_in_date

    for(var i = 0; i < this.state.form.days; i++){
      price += PlanStore.getTargetPrice(addDays(target_day,i), this.state.form.person_number)
    }
    console.log(price)
    /**for(var i = 0; i < item.schedules.length; i++){
        if(item.schedules[i].unit == this.state.form.person_number) && isEqual(this.state.form.check_in_date , new Date(item.schedules[i].target_date))){
          price = item.schedules[i].price
        }
    }**/

    this.setState({
      plan:item,
      price:price
    })
  }




  render(){

    if(!this.state.item || !this.state.plan || !this.state.form){
      return null
    }


    var options = this.state.item.bookOption
    var option_html = null
    var option_price = 0
  //  console.log(options)
    if(options){
      option_html = options.map((item) =>{

          var booked_option = null
          var unitnum = 0
          var price_num = 0
          for(var i = 0; i < this.state.form.options.length; i++){

            if(item.id == this.state.form.options[i].id ){
              booked_option = this.state.form.options[i]
              unitnum = booked_option.unit
              price_num = item.price * unitnum * this.state.form.days
              option_price += price_num
            }
          }

          return (
            <tr>
              <th>{item.name}</th>
              <td>
                {unitnum}{item.unit} ({price_num.toLocaleString()}円)
              </td>
            </tr>
          )
      });
    }


    var price_str = (this.state.price + option_price).toLocaleString()
  /*  if((this.state.form.price) != 0) {
      (this.state.form.price).toLocaleString()
    }
*/

    return(
      <section id="base-info-right">
        <h2>{this.state.item.name}</h2>
        <div className="photo-box"><img src={config.get("IMG_PATH") + this.state.item.main_image} /></div>
        <h3>{this.state.plan.name}</h3>
        <div className="book-form-day-box" >
          <table>
            <tbody>
              <tr><th>チェックイン</th><td>{this.state.form.check_in_date ? format(this.state.form.check_in_date, "yyyy年M月d日") : ""}</td></tr>
              <tr><th>チェックアウト</th><td>{this.state.form.check_out_date ? format(this.state.form.check_out_date, "yyyy年M月d日") : ""}</td></tr>
              <tr><th>宿泊人数</th><td>{this.state.form.person_number}人</td></tr>
              {option_html}
            </tbody>
          </table>
        </div>
        <div className="book-price-box" >
          <div className="left-text">お支払い合計(税込)
          </div>
          <div className="total-price">

            {price_str}円
          </div>
        </div>
      </section>

    )
  }
}
