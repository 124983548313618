import AppDispatcher from "../dispatcher/AppDispatcher"
import BookConstants from "../constants/BookConstants"

import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import { format } from 'date-fns'
import OptionActions from "./OptionActions";

var BookActions = {

  getDetail(secret, need_option = false){
    
    fetch(config.get("API2_PATH") + config.get("API2_BOOK") + "/" + secret, {
      method: 'GET',
      headers: {

     }
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {
    
      if(data != null){
        AppDispatcher.dispatch({
          actionType: BookConstants.GET_BOOKED_DETAIL,
          detail: data
        })
        if(need_option){
          OptionActions.getList(data.accommodation_id, data.room_id)
        }
      }
    })
  },

  cancel(id){


    fetch(config.get("API2_PATH") + config.get("API2_BOOK")+ "/" + id, {
      method: 'DELETE',
      headers: {}
    }).then(function(response) {
      if(response.status == 204){
        AppDispatcher.dispatch({
          actionType: BookConstants.CANCEL
        })
      }else if(response.status == 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {
      console.log(data)
    })
  },

  getList(accommodation_id,start_date, end_date){

    let params = new URLSearchParams();

    if(accommodation_id){
      params.set('accommodation_id', accommodation_id);
    }

    if(start_date){
      params.set('start_date', format(start_date, 'yyyy-MM-dd'));
    }
    if(end_date){
      params.set('end_date', format(end_date, 'yyyy-MM-dd'));
    }

    fetch(config.get("API2_PATH") + config.get("API2_CALENDAR") + "?" + params.toString(), {
      method: 'GET',
      headers: {
       'key': config.get("API2_KEY"),
       'id': config.get("API2_ID")
     }
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data != null ){
  
        AppDispatcher.dispatch({
          actionType: BookConstants.GET_BOOKED_LIST,
          list: data.calendars
        })
      }
    })
  },

  create(form){

    fetch(config.get("API2_PATH") + config.get("API2_BOOK"), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'key': config.get("API2_KEY"),
        'id': config.get("API2_ID")
      },
      body: JSON.stringify({
        "accommodation_id": Number(form.accommodation_id),
        "plan_id": Number(form.plan_id),
        "room_id": Number(form.room_id),
        "target_date": format(form.check_in_date,"yyyy-MM-dd"),
        "name":form.name,
        "address": form.prefecture + form.address,
        "tel":form.tel,
        "mail":form.mail,
        "person_number" : Number(form.person_number),
        "days":Number(form.days),
        "options":form.options,
        "memo":form.comment,
        "via":0
       })
     }).then(function(response) {
      if(response.status == 200){
         return response.json()
      }else{
        AppDispatcher.dispatch({
        actionType: BookConstants.ERROR_
        })
      }
    }).then(function(data) {
      console.log(data)
      if(data != null){
           AppDispatcher.dispatch({
             actionType: BookConstants.CREATED,
             usersecret:data.uid,
             group_id:data.group_id
           })

      }
    })
  }


}

export default BookActions
