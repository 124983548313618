import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import AuthConstants from "../constants/AuthConstants"
import assign from "object-assign"
import cookie from 'react-cookies'

var CHANGE_EVENT = "change" // chenge evnetを定数にする

var _todos = {} // 初期化

///AUTH
var _is_login = false
var _token = ""
var _refresh_token = ""

var _user_info = null
/*
*  ここから処理本体を書き始める
*/

var auth = (is_auth,token,refresh_token) => {
  _is_login = is_auth
  _token = token
  _refresh_token = refresh_token


}

var setUserInfo = (userinfo) => {
  _user_info = userinfo
}

var logout = () =>{
  _is_login = false
  _token = ""
  _refresh_token = ""
}
/*
var create = (text) => {
  var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
  _todos[id] = {
    id: id,
    text: text
  }
}
var destroy = (id) => {
  delete _todos[id]
}
*/


var AuthStore = assign({},EventEmitter.prototype,{

  /*getAll(){ // 今のtodo全てを返す
    return _todos
  },*/

  isAuth(){
    return _is_login
  },
  getToken(){
    return _token
  },

  getUserInfo(){
    return _user_info
  },

  emitChange(){ // 何かアクションがあったことを知らせる
    this.emit(CHANGE_EVENT)
  },

  addChangeListener(callback){ // リスナーに追加
    this.on(CHANGE_EVENT, callback)
  },

  removeChangeListener(callback){ // リスナーから削除
    this.removeListener(CHANGE_EVENT, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case AuthConstants.AUTH:

      auth(action.is_auth, action.token, action.refresh_token);
      AuthStore.emitChange()
      break;
    case AuthConstants.USER_INFO:
      setUserInfo(action.user_info);
      AuthStore.emitChange()
      break;
    case AuthConstants.LOGOUT:
      logout();
      AuthStore.emitChange()
      break;

    default:
      // no
  }
})
export default AuthStore
