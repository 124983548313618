import React from "react"

import styles from '../styles/book.css'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class CancelApp extends React.Component {

  constructor({match}){
    super()


  }

  componentDidMount(){

  }

  componentWillUnmount(){
    //AuthStore.removeChangeListener(this._onChange.bind(this));
  }




  render(){
    return(
      <div id="book">
        <section class="left-pannel">
          <h1>キャンセルが完了いたしました。</h1>

        </section>
        <section class="right-pannel">

        </section>

      </div>

    )
  }
}
