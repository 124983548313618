
import { Record } from 'immutable'

const Accommodation = Record({
  id:null,
  name: null,
  address:null,
  description: null,
  logo:null,
  main_image:null,
  bookOption:null
});
export default Accommodation;
