import React from "react"
import config from 'react-global-configuration';
import BookFormAction from  '../../../actions/BookFormActions'
import BookFormStore from "../../../stores/BookFormStore"
import AccommodationStore from "../../../stores/AccommodationStore"
import BookForm from "../../../vo/BookForm"
import BookStore from "../../../vo/BookForm"
import PlanStore from '../../../stores/PlanStore'
//import AuthActions from '../actions/AuthActions';

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Confirm extends React.Component {

  constructor(){
    super()
    this.BookFormStoreChangeHandler = this.BookFormStoreChangeHandler.bind(this)
    this.AccommodationStoreChangeHandler = this.AccommodationStoreChangeHandler.bind(this)
    this.confirm = this.confirm.bind(this)
    this.onChange = this.onChange.bind(this)
    this.updateForm = this.updateForm.bind(this)
    this.back = this.back.bind(this)
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this)
    this.changePlanHandler =this.changePlanHandler.bind(this)
    this.state = {
      item:null,
        accommodation:null,
        cancel_check:null,
        notice_check:null,
        message_notice:null,
        message_cancel:null


    }
  }

  componentDidMount(){
    PlanStore.addChangeListener(this.changePlanHandler)
    BookFormStore.addChangeListener(this.BookFormStoreChangeHandler)
    AccommodationStore.addChangeListener(this.AccommodationStoreChangeHandler)
    this.setState({
      item:BookFormStore.getDetail()
    })
  }

  componentWillUnmount(){
    PlanStore.removeChangeListener(this.changePlanHandler)
    BookFormStore.removeChangeListener(this.BookFormStoreChangeHandler)
      AccommodationStore.removeChangeListener(this.AccommodationStoreChangeHandler)
  }

  AccommodationStoreChangeHandler(){
    this.setState({
      accommodation:AccommodationStore.getDetail()
    })
  }

  changePlanHandler(){
    this.setState({
      plan:PlanStore.getDetail()
    })
  }
  onChange(e){
    const target = e.target
    this.props.updateForm(target.name, target.value)
  }

  updateForm(name,value){
    this.props.updateForm(name,value)
  }


  BookFormStoreChangeHandler(){

  }

  checkboxChangeHandler(e){
    const target = e.target

    switch(target.name){
      case "cancel_check":{
        this.setState({
          cancel_check:target.checked
        })
      }

      case "notice_check":{
        this.setState({
          notice_check:target.checked
        })
      }
    }
  }
  confirm(){
    if(this.state.cancel_check && this.state.notice_check){
      this.props.confirm()
    }else{
      if(!this.state.cancel_check){
          this.setState({
            message_notice:"注意事項に同意してください。"
          })
      }
      if(!this.state.notice_check){
        this.setState({
          message_cancel:"キャンセルポリシーに同意してください。"
        })
      }
    }

  }
  back(){
    this.props.back()
  }
  render(){
    if(!this.state.plan || !this.state.item || !this.state.accommodation){
      return null
    }


    var avalilavle_payment_method = []
    const payment_method = this.state.plan.payment_methods
    for(var t = 0; t < payment_method.length; t++){
      avalilavle_payment_method.push(<span>{payment_method[t].name}　</span>)
    }

    var options = this.state.accommodation.bookOption
    var option_html = null
    if(options){
      option_html = options.map((item) =>{
          var value = 0
          for(var i = 0 ; i < this.state.item.options.length; i++){
            if(this.state.item.options[i].id == item.id){
              value = this.state.item.options[i].unit
            }
          }
          return (
            <tr>
              <th>{item.name}</th>
              <td>
                  {value}{item.unit}
              </td>
            </tr>
          )
      });
    }



    return(
      <div>
        <table>
          <tbody>
            <tr><th>お名前</th><td>{this.state.item.name}</td></tr>
            <tr><th>メールアドレス</th><td>{this.state.item.mail}</td></tr>
            <tr><th>都道府県</th><td>{config.get("PREF_" + (this.state.item.prefecture))}</td></tr>
            <tr><th>住所</th><td>{this.state.item.address}</td></tr>
            <tr><th>電話番号</th><td>{this.state.item.tel}</td></tr>
            {option_html}
            <tr><th>宿への連絡事項</th><td>{this.state.item.comment}</td></tr>
            <tr><th>利用できる支払い方法</th><td>{avalilavle_payment_method} </td></tr>
          </tbody>
        </table>
        <section className="description"><p dangerouslySetInnerHTML={{__html: this.state.accommodation ? config.get("CONFIRM_TEXT_" +this.state.accommodation.id) : null}}></p>
        </section>
        <section className="checkbox">
          <input type="checkbox"  name="notice_check" onChange={this.checkboxChangeHandler} />上記注意事項に同意する
          <p className="message">{this.state.message_notice}</p>
        </section>

      <section className="description">
      <h2>キャンセルポリシー</h2>
      <p>
      キャンセルポリシーといたしましては、下記の通りです。 天候等による払い戻しはありません。<br/>
      （ただし、警報レベルの台風の場合や、主要の交通機関が遮断され、お越しいただけない場合はキャンセル料は発生いたしません。
       全額払い戻しいたします。）
       </p>
       <p>
      ご宿泊日より30日前から11日前まで→ご宿泊料の10%<br/>
      ご宿泊日の10日前から4日前まで→ご宿泊料の50%<br/>
      ご宿泊日より3日前から当日まで→ご宿泊料の100%
      </p>
      </section>
      <section className="checkbox">
        <input type="checkbox" name="cancel_check" onChange={this.checkboxChangeHandler} />キャンセルポリシーに同意する
        <p className="message">{this.state.message_cancel}</p>
      </section>

        <div className="button-box">
          <button className="white big" onClick={this.back}>戻る</button>
          <button className="big" onClick={this.confirm}>予約の確定</button>
        </div>
      </div>

    )
  }
}
