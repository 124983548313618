import React from "react"
import config from 'react-global-configuration';
import BookStore from "../../../stores/BookStore"
import PlanStore from '../../../stores/PlanStore'
import Common from "../../../utils/Common"
import PlanActions from '../../../actions/PlanActions'
import { format } from 'date-fns'

//import AuthActions from '../actions/AuthActions';

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Confirm extends React.Component {

  constructor(){
    super()
    this.BookStoreChangeHandler = this.BookStoreChangeHandler.bind(this)
    this.changePlanHandler =this.changePlanHandler.bind(this)
    PlanStore.addChangeListener(this.changePlanHandler)


    this.state = {
      item:null,
      plan:null,
      message: "読み込み中..."
    }
  }

  componentDidMount(){
    BookStore.addChangeListener(this.BookStoreChangeHandler)
  }

  componentWillUnmount(){
    BookStore.removeChangeListener(this.BookStoreChangeHandler)
    PlanStore.removeChangeListener(this.changePlanHandler)
  }

  BookStoreChangeHandler(){
    this.setState({
      item:BookStore.getDetail()
    })
  }

  changePlanHandler(){
    const p = PlanStore.getDetail()
    var m = "-"
    if(p == null){
        m = "予約情報がありません"
    }
    this.setState({
      plan: PlanStore.getDetail(),
      message:m
    })

  }




  render(){
    if(!this.state.item || !this.state.item.target_date){
      return null
    }

    if(this.state.item.delete_flg == 1){
      return <div className="message">この予約は削除されています。 </div>
    }

    if(!this.props.item){
      return this.state.message
    }

    const payment_method = this.state.item.payment_methods ? this.state.item.payment_methods.map(item => {
      return <div>{item.name}</div>
    }) : null

    const options_html = this.state.item.options.map(item => {
      return <div>{item.name}:{item.unit}{item.unit_name}</div>
    })

    var total_paid = 0
    for(var i = 0; i < this.state.item.payments.length; i++){
      total_paid += this.state.item.payments[i].price
    }
    var paied_status = total_paid === 0 ? "未入金" : total_paid >0 && total_paid < this.state.item.price ? "一部入金" : "入金完了"

    return(
      <div>

        <table>
          <tbody>
            <tr><th>お名前</th><td>{this.state.item.name}</td></tr>
            <tr><th>メールアドレス</th><td>{this.state.item.mail}</td></tr>
            <tr><th>プラン名</th><td>{this.state.item.plan_name}</td></tr>
            <tr><th>部屋タイプ</th><td>{this.state.item.room_name}</td></tr>
            <tr><th>金額</th><td>{this.state.item.price.toLocaleString()}円 (税込)</td></tr>
            <tr><th>宿泊日</th><td>{format(new Date(this.state.item.target_date), "yyyy年M月d日")}から{this.state.item.days}泊</td></tr>
            <tr><th>オプション</th><td>{options_html.length > 0 ? options_html :"ご予約完了メールにオプションの内容を記載させていただいております。ご希望の方はご覧いただきメールに返信にてオーダーくださいませ。"}</td></tr>
            <tr><th>支払い状況</th><td>{paied_status}</td></tr>
            <tr><th>支払い期限</th><td>{Common.getDateJpString(this.state.item.payment_limit_date)}<br/>期限までに決済あるいはお振込が確認できない場合、キャンセルさせていただく場合がございます</td></tr>
            <tr><th>ご利用可能な支払い方法</th><td>{payment_method}</td></tr>

          </tbody>
        </table>

      </div>



    )
  }
}
