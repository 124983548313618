import AppDispatcher from "../dispatcher/AppDispatcher"
import CalendarConstants from "../constants/CalendarConstants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'


var CalendarActions = {
  setCurrentMonth(year,month){
    AppDispatcher.dispatch({
      actionType: CalendarConstants.SET_CURRENT_YEAR_MONTH,
      year:year,
      month: month
    })
  },

  /*getList(token,name=null){
    fetch(config.get("API_PATH") + config.get("API_BRAND_SEARCH"), {
      method: 'POST',
      headers: {
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({
       access_token: token,
       data:{

       }
    })
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        console.log("auth error!")
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data != null && data.status == 1){
        AppDispatcher.dispatch({
          actionType: Constants.BRAND_LIST_LOADED,
          list: data.brands
        })
      }
    })
  },
  */



}

export default CalendarActions