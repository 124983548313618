import React from "react"
import config from 'react-global-configuration';
import BookFormAction from  '../../../actions/BookFormActions'
import BookFormStore from "../../../stores/BookFormStore"
import AccommodationStore from "../../../stores/AccommodationStore"
import BookForm from "../../../vo/BookForm"
import PrefectureSelection from "../common/PrefectureSelection"
import BookStore from "../../../vo/BookForm"
import PlanStore from "../../../stores/PlanStore"
import BookOptionValue from "../../../vo/BookOptionValue"
import Common from "../../../utils/Common"
//import AuthActions from '../actions/AuthActions';

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Book extends React.Component {

  constructor(){
    super()
    this.BookFormStoreChangeHandler = this.BookFormStoreChangeHandler.bind(this)
    this.confirm = this.confirm.bind(this)
    this.back = this.back.bind(this)
    this.onChange = this.onChange.bind(this)
    this.updateForm = this.updateForm.bind(this)
    this.AccommodationStoreChangeHandler = this.AccommodationStoreChangeHandler.bind(this)
    this.changeOptionHandler = this.changeOptionHandler.bind(this)
    this.changePlanHandler = this.changePlanHandler.bind(this)
    this.state = {
      item:new BookForm(),
      plan:null,
      accommodation:null,
      message_name:null,
      message_mail:null,
      message_mail_confirm:null,
      message_tel:null,
      mail_validate:true
    }
  }

  componentDidMount(){
    PlanStore.addChangeListener(this.changePlanHandler)
    BookFormStore.addChangeListener(this.BookFormStoreChangeHandler)
    AccommodationStore.addChangeListener(this.AccommodationStoreChangeHandler)
  }

  componentWillUnmount(){
    PlanStore.removeChangeListener(this.changePlanHandler)
    BookFormStore.removeChangeListener(this.BookFormStoreChangeHandler)
    AccommodationStore.removeChangeListener(this.AccommodationStoreChangeHandler)
  }

  onChange(e){
    const target = e.target
    if(target.name == "mail"){
      if(target.validationMessage == ""){
        this.setState({
          mail_validate : true
        })
      }else{
        this.setState({
          mail_validate : false
        })
      }
    }
    this.props.updateForm(target.name, target.value)
  }

  updateForm(name,value){
    this.props.updateForm(name,value)
  }

  AccommodationStoreChangeHandler(){
    this.setState({
      accommodation:AccommodationStore.getDetail()
    })
  }

  BookFormStoreChangeHandler(){
    this.setState({
      item:BookFormStore.getDetail()
    })
  }
  changePlanHandler(){
    this.setState({
      plan:PlanStore.getDetail()
    })
  }

  changeOptionHandler(e){
    const target= e.target
    const book_opt_value = new BookOptionValue({
      id:Number(target.getAttribute("opt_id")),
      unit:Number(target.value)
    })
    this.props.updateOptionForm(book_opt_value)

  }
  confirm(){
    //validate
    var is_validated = true
    if(!this.state.item.name || this.state.item.name == ""){
      is_validated = false
      this.setState({message_name:config.get("ERROR_REQUIRED")})
    }

    if(!this.state.item.mail || this.state.item.mail == ""){
      is_validated = false
      this.setState({message_mail:config.get("ERROR_REQUIRED")})
    }else if(!this.state.mail_validate){
      is_validated = false
      this.setState({message_mail:config.get("ERROR_MAIL")})
    }else if(this.state.item.mail != this.state.item.mail_confirm){
      is_validated = false
      this.setState({message_mail_confirm:config.get("ERROR_MAIL_CONFIRM")})
    }

    if(!this.state.item.tel || this.state.item.tel == ""){
      is_validated = false
      this.setState({message_tel:config.get("ERROR_REQUIRED")})
    }

    if(is_validated){

        this.props.confirm()
    }

  }

  back(){
    this.props.back()
  }

  render(){

    if(!this.state.plan || !this.state.item || !this.state.accommodation){
      return null
    }

    var options = this.state.accommodation.bookOption
    var option_html = null
    if(options){
      option_html = options.map((item) =>{
          var input_select = []
          input_select.push(<option value={0} selected key="select_0">0{item.unit}</option>)
          for(var i = 1 ; i <= item.unit_max; i++){
            input_select.push(<option value={i} key={"select_" + i}>{i}{item.unit}</option>)
          }
          return (
            <tr key="option_tr">
              <th>{item.name}</th>
              <td>
                <div className="m-select_group">
                  <select className="m-select m-form" name={"option_" + item.id} onChange={this.changeOptionHandler} opt_id={item.id} key={item.id}>
                    {input_select}
                  </select>

                </div>
                <div className="description">
                  {Common.nl2br(item.description)}
                </div>
              </td>
            </tr>
          )
      });
    }

    var avalilavle_payment_method = []
    const payment_method = this.state.plan.payment_methods
    for(var t = 0; t < payment_method.length; t++){
      avalilavle_payment_method.push(<span>{payment_method[t].name}　</span>)
    }


    return(
      <div>
        <table>
          <tbody>
            <tr><th>お名前</th><td><input type="text" value={this.state.item.name} onChange={this.onChange} name="name"/><p className="message">{this.state.message_name}</p></td></tr>
            <tr><th>メールアドレス</th><td><input type="email" value={this.state.item.mail} onChange={this.onChange} name="mail" /><p className="message">{this.state.message_mail}</p></td></tr>
            <tr><th>メールアドレス(確認)</th><td><input type="email" value={this.state.item.mail_confirm} onChange={this.onChange} name="mail_confirm" /><p className="message">{this.state.message_mail_confirm}</p></td></tr>
            <tr><th>都道府県</th><td><PrefectureSelection updateForm={this.updateForm} item={this.state.item} /></td></tr>
            <tr><th>住所</th><td><input type="text" value={this.state.item.address} onChange={this.onChange} name="address" /></td></tr>
            <tr><th>電話番号</th><td><input type="text" value={this.state.item.tel} onChange={this.onChange} name="tel" /><p className="message">{this.state.message_tel}</p></td></tr>
            {option_html}
            <tr><th>宿への連絡事項</th><td><textarea onChange={this.onChange} name="comment">{this.state.item.comment}</textarea></td></tr>
            <tr><th>利用できる支払い方法</th><td>{avalilavle_payment_method}</td></tr>
          </tbody>
        </table>
        <p className="message2">
        現地での決済対応はしておりません。<br/>
事前決済のみとなり、銀行振込かクレジットカード決済かお選びいただくことになります。<br/>
振込先や期限、クレジットカード決済期限につきましてはメールをお送りいたします。<br/>
必ずメールが届くかどうかをご確認お願い致します。<br/>
迷惑メールに入ってしまったり、携帯で受信拒否されてしまう場合もございます。<br/>
メールが24時間以内に届かない場合、guest@thehousehayama.comまでお手数ですがお問い合わせをお願い致します。<br/>
期限までに決済が完了しない場合、場合によってはキャンセルになることがございます。
        </p>
        <div className="button-box">
          <button className="white big" onClick={this.back}>戻る</button>
          <button className="big" onClick={this.confirm}>予約の確認</button>
        </div>

      </div>

    )
  }
}
