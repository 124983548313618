import React, { useState,useEffect ,useCallback} from 'react';
import config from 'react-global-configuration';
import BookStore from "../../../stores/BookStore"
import {format, addDays } from 'date-fns'
import ja from 'date-fns/locale/ja'
import Common from '../../../utils/Common';

function PlanItem(props) {
  const [days_list, setDaysList] = useState(null)
  useEffect(() => {
 
    var scs = []
    var total_price = 0;
    for(var i = 0; i < Number(props.days); i++){
      const schedule = BookStore.getSchedule(props.room_id, props.plan.id, addDays(props.target_date,i), props.person_number)
      scs.push(
        <div>{(i+1)}泊目 {format(addDays(props.target_date,i),"M月d日(EEEEEE)", {locale: ja})} : {schedule.price.toLocaleString()}円</div>
      )
      total_price += schedule.price
    }
    scs.push(<div className="total">合計 : {total_price.toLocaleString()}円</div>)
    setDaysList(scs)


  },[]);


  function moveTo(){
    window.location.href = "/book/" + props.accommodation_id + "/" + props.plan.id + "/" + props.room_id + "/" + format(props.target_date,"yyyy")  + "/" + format(props.target_date,"MM") + "/" + format(props.target_date,"dd") + "/" + props.person_number + "/" +props.days
  }


  return (
    <div className="plan-item">
      <div className="plan-name">{props.plan.name}<div className="plan-name-sub">{props.plan.description ? Common.nl2br(props.plan.description) : null}</div></div>
      <div className="days">{days_list}</div><div className="book-btn"><button onClick={moveTo}>予約する</button></div>
    </div>
  )
}
export default PlanItem



/** import React from "react"
import config from 'react-global-configuration';
import CalendarStore from "../../../stores/CalendarStore"
import PlanStore from "../../../stores/PlanStore"
import BookStore from "../../../stores/BookStore"
//import AuthActions from '../actions/AuthActions';


export default class PlanItem extends React.Component {

  constructor(){
    super()
    this.moveTo = this.moveTo.bind(this)
    this.state = {
    }
  }


  moveTo(plan_id, room_id){
      this.props.moveTo(plan_id, room_id)
  }

  render(){

    const plans = this.props.item.plans.map( item => {

      var price = 0;
      for(var i = 0; i < item.schedules.length; i++){
        if(item.schedules[i].unit == this.props.person_number){
          price = item.schedules[i].price
        }
      }
      return(<tr>
        <td className="plan-name">{item.name} <span className="is-mob"></span></td>
        <td className="plan-inout">IN：{item.check_in}　OUT：{item.check_out}</td>
        <td className="plan-room">{this.props.item.name}</td>
        <td className="plan-price">{price.toLocaleString()}円 (税込)</td>
        <td className="plan-button"><button onClick={() => this.moveTo(item.id, this.props.item.id)}>予約する</button></td>
      </tr>
      )
    })



    return(plans)
  }
}
**/
