import AppDispatcher from "../dispatcher/AppDispatcher"
import AccommodationConstants from "../constants/AccommodationConstants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import { format } from 'date-fns'

var FaqActions = {

  getList(accommodation_id,room_id){

    const room_id_str = room_id ? "?room_id=" + room_id : ""


    fetch(config.get("API2_PATH") + config.get("API2_ACCOMMODATION") +"/" +accommodation_id + "/faqs" + room_id_str, {
      method: 'GET'
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {

      if(data != null ){
        AppDispatcher.dispatch({
          actionType: AccommodationConstants.GET_FAQ_LIST,
          list: data
        })
      }
    })
  },


}

export default FaqActions