import React, { useState, useEffect,useCallback } from 'react'
import {useParams} from 'react-router-dom';
import {format, isBefore} from "date-fns"
import OptionStore from '../stores/OptionStore';
import BookStore from '../stores/BookStore';
import CardStore from '../stores/CardStore';
import OptionItem from "./parts/book/OptionItem"
import CardActions from '../actions/CardActions';
import BookActions from '../actions/BookActions';
import OptionActions from '../actions/OptionActions';
import Card from './parts/book/Card'

function OptionApp(props:PropsValue) {


  const { usersecret } = useParams();
  const [item, setBookItem] = useState(null)
  const [list, setList] = useState([])
  const [options, setOptions] = useState(null)
  const [total, setTotal] = useState(0)
  const [card, setCard] = useState(<div className="button-box"><button className="huge create" onClick={displayCard}>カードで支払う</button></div>)
  const [message, setMessage] = useState(null)

  const stableOptionChangeHandler = useCallback(optionChangeHandler,[])
  const stableBookChangeHandler = useCallback(bookChangeHandler,[])

  useEffect(() => {
    OptionStore.addChangeListener(stableOptionChangeHandler)
    BookStore.addChangeListener(stableBookChangeHandler)
    BookActions.getDetail(usersecret, true)

    CardStore.addChangeListener(cardErrorHandler,"error_card")
    CardStore.addChangeListener(payErrorHandler,"error_pay")
    return function cleanup() {
      OptionStore.removeChangeListener(stableOptionChangeHandler)
      BookStore.removeChangeListener(stableBookChangeHandler)
    
      CardStore.removeChangeListener(cardErrorHandler,"error_card")
      CardStore.removeChangeListener(payErrorHandler,"error_pay")
    };
  },[stableBookChangeHandler,stableOptionChangeHandler]);

  function changeHandler(e){
    const target = e.target 
  }

  function optionChangeHandler(){
    const book = BookStore.getDetail()
    const today = new Date()
    const is_before = isBefore(today,new Date(book.target_date) )


    setList(
      OptionStore.getList().map(item => {
       
        if(item.is_stay_type === 1){
          return (<OptionItem item={item} createOption={createOption}/>)
        }else if(is_before){
          return (<OptionItem item={item} createOption={createOption}/>)
        }else{
          return null
        }
       
      })
    )
    
  }



  function bookChangeHandler(){
    const item = BookStore.getDetail()
 
    setBookItem(item)
    setOptions(item ? item.options.map(op => {  return <div className="margin_bottom">{op.name} ({op.price.toLocaleString()}円) x{op.unit} x{op.days}泊 {op.paid_at ? <span className="sml">[支払済]</span> : <span className="link sml bold" onClick={()=> deletItem(op)}>[削除]</span>}</div>}) : null)
    var total = 0 
    if(item) {
      for(var i = 0; i < item.options.length; i++){
        const op = item.options[i]
        if(!op.paid_at) total += (op.price )
      }
      setTotal(total)
    }
    
  }

  function goToBookDetail(){
    window.location.href = "/book/detail/" + usersecret
  }

  function createOption(id, unit, days){
    const item = BookStore.getDetail()
    OptionActions.create(item.uid,id,Number(unit), Number(days))
  }

  function deletItem(op){
    const item = BookStore.getDetail()
    OptionActions.delete(item.uid,op.id)
  }

  function updateForm(name,value){
    CardActions.update(name,value)
  }

  function getToken(card){
    CardActions.createToken(card)
  }

  function createPayment(){
    const item = BookStore.getDetail()
    var total = 0 
    if(item) {
      for(var i = 0; i < item.options.length; i++){
        const op = item.options[i]
        if(!op.paid_at) total += op.price 
      }
    }
    
    CardActions.paymentExe(CardStore.getToken(),item.uid,total,"option")
  }

  function displayCard(){
    setCard(<Card updateForm={updateForm} getToken={getToken} createPayment = {createPayment}/> )
  }

  function cardErrorHandler(){
    setMessage("カード情報が認証されません。内容を確認してください。 Error Coce:" +CardStore.getErrorCode())
  }

  function payErrorHandler(){
    setMessage("支払いが認証されませんでした。支払い方法をご確認ください。 Error Coce:" +CardStore.getErrorCode())
  }

  return(
    <div id="book">
        <section class="left-pannel">
          <h1>オプション詳細</h1>
          <div>

            <table>
              <tbody>
                <tr><th>お名前</th><td>{item ? item.name : null}</td></tr>
                <tr><th>部屋タイプ</th><td>{item ? item.room_name : null}</td></tr>
                <tr><th>宿泊日</th><td>{item ? format(new Date(item.target_date), "yyyy年M月d日") + "から" + item.days + "泊" : null}</td></tr>
                <tr><th>オプション</th><td>{options}</td></tr>
                <tr><th>未決済金額</th><td>{total.toLocaleString()}円</td></tr>
              </tbody>
            </table>
            
            {card}
            <div className="message">{message}</div>
   
          </div>
          <h2 className="bookdetail">オプションの追加</h2>
          <div className="option-list">
            {list}
          </div>
          <div className="button-box">
           <button className="huge white" onClick={goToBookDetail}>予約詳細</button>
         
          </div>
        </section>

     
      </div>
  )
}

export default OptionApp
