import React from "react"

import CardActions from '../actions/CardActions';
import BookActions from '../actions/BookActions';
import PlanActions from '../actions/PlanActions'
import AuthActions from '../actions/AuthActions';
import SquareActions from "../actions/SquareActions";
import AccommodationActions from '../actions/AccommodationActions';
import BookStore from '../stores/BookStore'
import CardStore from '../stores/CardStore'
import AuthStore from '../stores/AuthStore'
import Detail from './parts/book/Detail'
import CardSquare from "./parts/book/CardSquare";

import styles from '../styles/book.css'
import config from 'react-global-configuration';

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class BookDetailApp extends React.Component {

  constructor({match}){
    super()
    this.cancel = this.cancel.bind(this)
    this.cancelDisplay = this.cancelDisplay.bind(this)
    this.hideDisplay = this.hideDisplay.bind(this)
    
    this.canselChangeHandler = this.canselChangeHandler.bind(this)
    this.authChangeHandler = this.authChangeHandler.bind(this)
    this.createPayment = this.createPayment.bind(this)
    this.getCardEntry = this.getCardEntry.bind(this)
    this.BookStoreChangeHandler = this.BookStoreChangeHandler.bind(this)
    this.PaymentChangeHandler = this.PaymentChangeHandler.bind(this)
    this.CardErrorHandler = this.CardErrorHandler.bind(this)
    this.getToken = this.getToken.bind(this)
    this.PayErrorHandler = this.PayErrorHandler.bind(this)

    this.state = {
      item:null,
      usersecret:match.params.usersecret,
      is_auth:false,
      is_card_entry:false,
      message:null,
      error_code:null,
      cancel_alert:false
    }
  }

  componentDidMount(){

    AuthStore.addChangeListener(this.authChangeHandler)
    BookStore.addChangeListener(this.canselChangeHandler,"cancel")
    BookStore.addChangeListener(this.BookStoreChangeHandler)
    CardStore.addChangeListener(this.PaymentChangeHandler,"change_payment")
    CardStore.addChangeListener(this.CardErrorHandler,"error_card")
    CardStore.addChangeListener(this.PayErrorHandler,"error_pay")

    BookActions.getDetail(this.state.usersecret)

  }

  componentWillUnmount(){
    AuthStore.removeChangeListener(this.authChangeHandler)
    BookStore.removeChangeListener(this.canselChangeHandler,"cancel")
    BookStore.removeChangeListener(this.BookStoreChangeHandler)
    CardStore.removeChangeListener(this.PaymentChangeHandler,"change_payment")
    CardStore.removeChangeListener(this.CardErrorHandler,"error_card")
    CardStore.removeChangeListener(this.PayErrorHandler,"error_pay")
  }

  PaymentChangeHandler(){
    BookActions.getDetail(this.state.usersecret,this.state.group_id)
    this.setState({
      message:  <p className="message3 cent">支払いが完了しました。ありがとうございました。</p>
    })
  }

  BookStoreChangeHandler(){
    const book_item = BookStore.getDetail()

    this.setState({
      item:book_item
    })
  }

  canselChangeHandler(){
    this.props.history.push("/cancel")
  }

  cancel(){
    BookActions.cancel(this.state.usersecret)
  }

  hideDisplay(){
    this.setState({
      cancel_alert:false
    })
  }

  cancelDisplay(){
    console.log("cancel")
    this.setState({
      cancel_alert:true
    })
  }

  backToHp(){
      window.location = config.get("HP")
  }

  updateForm(name,value){
    CardActions.update(name,value)
  }

  authChangeHandler(){

    this.setState({
      is_auth:AuthStore.isAuth()
    })
  }


  CardErrorHandler(){
    this.setState({
      error_code: CardStore.getErrorCode(),
     // message: "カード情報が認証されません。内容を確認してください。 Error Coce:" +CardStore.getErrorCode()
    })
  }

  PayErrorHandler(){
    this.setState({
      //message: "支払いが認証されませんでした。支払い方法をご確認ください。Error Coce:" +CardStore.getErrorCode()
    })
  }


  getToken(element){
    this.setState({
      message: null
    })
   // CardActions.createToken(this.payjp, element)
  }
  getCardEntry(){
    //CardActions.getAvailableCard(this.payjp)
    this.setState({
      is_card_entry:true
    })
  }
  createPayment(){
    this.setState({
      message: null
    })
    const item = BookStore.getDetail()

    SquareActions.paymentExe(CardStore.getToken(),item.book_id,item.price)

  }

  render(){
    var cardHTML = null
    var btnHTML = null

    if(this.state.is_card_entry && this.state.item && (this.state.item.status == 0 || this.state.item.status == 4) && (!this.state.item.payments || this.state.item.payments.length === 0 ) ){
      cardHTML = <CardSquare book_id={this.state.usersecret} amount={this.state.item.price} btn_text={"決済を実行する"} is_option="option" />
    }

    if(!this.state.is_card_entry && this.state.item && this.state.item.delete_flg === 0 && (!this.state.item.payments || this.state.item.payments.length === 0 ) ){
      btnHTML = <div className="button-box">
          <button className="huge" onClick={this.getCardEntry}>カードで支払いをする</button>
        </div>
    }
    return(

      <div id="book">
        {
          this.state.cancel_alert ? <div className="alert-window"><div className="background"></div><div className="content"><button className="huge white" onClick={this.hideDisplay}>閉じる</button><button className="huge white" onClick={this.cancel}>予約をキャンセルする</button></div></div> : null
        }
        <section class="left-pannel">
          <h1>予約情報の確認</h1>
          <Detail item={this.state.item}/>
          

          
          {cardHTML}

         {this.state.message}


          {btnHTML}
          <div className="button-box">
            <button className="huge white" onClick={this.backToHp}> THE HOUSE公式サイトへ戻る</button>
            {  this.state.item && this.state.item.delete_flg === 0 ? <button className="huge white" onClick={this.cancelDisplay}>予約をキャンセルする</button> : null}
          </div>
        </section>


      </div>

    )
  }
}
