import keyMirror from "keymirror"

export default keyMirror({
  ///Messagections
  UPDATE_CARD:null,
  UPDATE_TOKEN:null,
  PAYMENT_DONE:null,
  ERROR_CARD:null,
  ERROR_PAY:null,
  SQUARE_CARD:null,
  ERROR_SQUARE:null,


})
