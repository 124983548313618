import React from "react"
import config from 'react-global-configuration';
import style from '../../../styles/header.css'
import AccommodationStore from "../../../stores/AccommodationStore"
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Header extends React.Component {

  constructor(){
    super()
    this.AccommodationStoreChangeHandler = this.AccommodationStoreChangeHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.state = {
      item:null
    }
  }

  componentDidMount(){
    AccommodationStore.addChangeListener(this.AccommodationStoreChangeHandler)
  }

  componentWillUnmount(){
    AccommodationStore.removeChangeListener(this.AccommodationStoreChangeHandler)
  }

  AccommodationStoreChangeHandler(){

    this.setState({
      item:AccommodationStore.getDetail()
    })
  }

  clickHandler(){
    window.location = config.get("HP_" +this.state.item.id )
  }


  render(){

    if(!this.state.item){
      return null
    }

    var description = ""
    if(this.state.item.description){
      description = this.state.item.description.replace(/\r?\n/g, '<br>')
    }
    var img_photo = ""
    if(this.state.item.main_image){
      img_photo =  <img src={config.get("IMG_PATH") + this.state.item.main_image} />
    }

    var img_logo = ""
    if(this.state.item.logo){
      img_logo =  <img src={config.get("IMG_PATH") + this.state.item.logo} />
    }


    return(
      <section id="base-info">
        <h1>{img_logo}</h1>
        <div className="flex-row-box">
          <div className="photo-box">{img_photo}</div>
          <div className="info-box">
            <h2>{this.state.item.name}</h2>
            <p>所在地: {this.state.item.address}</p>
            <p>基本情報：</p>
            <p dangerouslySetInnerHTML={{__html: description}}>
            </p>
             {config.get("IK_" +this.state.item.id ) ? <p className="message"> GOTOトラベルキャンペーンの期間中（延期される可能性もある期間）は全て一休.comでのご予約のみでお受けしております。ご予約は<a href={config.get("IK_" +this.state.item.id )}>一休.comの予約ページから</a>ご予約ください。</p> : null }
            <button onClick={this.clickHandler}>施設公式ページはこちら</button>
          </div>
        </div>
      </section>

    )
  }
}
