import React from "react"
import config from 'react-global-configuration';
import style from '../../../styles/header.css'
import PlanStore from "../../../stores/PlanStore"
import BookFormStore from "../../../stores/BookFormStore"
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class BookNumSelector extends React.Component {

  constructor(){
    super()
    this.PlanStoreChangeHandler = this.PlanStoreChangeHandler.bind(this)
    this.BookFormStoreChangeHandler = this.BookFormStoreChangeHandler.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.state = {
      max_num:null,
      book_form:null
    }
  }

  componentDidMount(){
    PlanStore.addChangeListener(this.PlanStoreChangeHandler)
    BookFormStore.addChangeListener(this.BookFormStoreChangeHandler)

  }

  componentWillUnmount(){
    PlanStore.removeChangeListener(this.PlanStoreChangeHandler)
    BookFormStore.removeChangeListener(this.BookFormStoreChangeHandler)
  }

  PlanStoreChangeHandler(){
    this.setState({
      max_num:PlanStore.getMaxStayNum()
    })
  }
  BookFormStoreChangeHandler(){
    this.setState({
      book_form:BookFormStore.getDetail()
    })
  }
  changeHandler(e){
    const target = e.target
    this.props.updateBookForm("person_number",target.value)
  }

  render(){
    if(! this.state.max_num) return null
    if(! this.state.book_form) return null

    var option_list = []
    for(var i = 0; i < this.state.max_num; i++){
       option_list.push(<option value={i+1} key={"select_" + i}>{i+1}</option>)
    }

    return(
      <div className="book-num-select">
        <div className="m-select_group">
          <select className="m-select m-form" name="book_num" value={this.state.book_form.person_number} onChange={this.changeHandler}>
          {option_list}
          </select>
        </div>
      </div>

    )
  }
}
