import AppDispatcher from "../dispatcher/AppDispatcher"
import CardConstants from "../constants/CardConstants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import BookActions from "./BookActions";


var SquareActions = {
  update(name,value){

    AppDispatcher.dispatch({
      actionType: CardConstants.UPDATE_CARD,
      name: name,
      value:value
    })

  },

  init(){
    if(window.Square){
      
      const payments = window.Square.payments(config.get("SQUARE_APP_ID"), config.get("SQUARE_LOCATION_ID"));

      payments.card({postalCode : "000000"}).then(function(data) {
        AppDispatcher.dispatch({
          actionType: CardConstants.SQUARE_CARD,
          card: data
        })
      })

    }else{
      console.log("error")
      AppDispatcher.dispatch({
        actionType: CardConstants.ERROR_SQUARE,
        code: config.get("ERROR_SQUARE")
      })
    }
  },

  payBySquare(card, book_id, amount, is_option="false"){
    card.tokenize().then(function(tokenResult){
      if (tokenResult.status === 'OK') {
        SquareActions.paymentExe(tokenResult.token, book_id,amount, is_option)
        console.log()
      } else {
        AppDispatcher.dispatch({
          actionType: CardConstants.ERROR_SQUARE,
          card: config.get("ERROR_SQUARE_CARD")
        })
      }
    });
   
  },
  
  paymentExe(pay_token,book_id,amount, is_option = "false"){
    fetch(config.get("API2_PATH") + config.get("API2_BOOK") + "/" + book_id + config.get("API2_PAYMENT"), {
      method: 'POST',
      headers: {
       'Content-Type': 'application/json',
       'key': config.get("API2_KEY"),
       'id': config.get("API2_ID")
     },
     body: JSON.stringify({
      payment_id:config.get("PAYMENT_METHOD_SQUARE"),
      amount:amount,
      card_token:pay_token,
      is_option: is_option
    })
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {

      if(data != null){
        if(is_option){
          BookActions.getDetail(book_id)
        }
        AppDispatcher.dispatch({
          actionType: CardConstants.PAYMENT_DONE,
        })
      }else{
  
        AppDispatcher.dispatch({
          actionType: CardConstants.ERROR_PAY,
          code:data
        })
      }
    })


  }
  /*
  update(name,value){


    AppDispatcher.dispatch({
      actionType: BookConstants.UPDATE,
      name: name,
      value:value
    })

  },

  addOption(value){

    AppDispatcher.dispatch({
      actionType: BookConstants.ADD_OPTION,
      value:value
    })

  },
  */




}

export default SquareActions
