import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import AccommodationConstants from "../constants/AccommodationConstants"
import assign from "object-assign"
import Accommodation from "../vo/Accommodation"

var CHANGE_EVENT = "change" // chenge evnetを定数にする

var _accommodation_detail = null


var setDetail= (item) =>{
  _accommodation_detail = item
}


var AccommodationStore = assign({},EventEmitter.prototype,{



  getDetail(){
    return _accommodation_detail
  },


  createRecord(item){

    return new Accommodation({
      id:item.id,
      name:item.name,
      description:item.description,
      logo:item.logo,
      main_image:item.main_image,
      bookOption:item.option,
      address:item.address
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case AccommodationConstants.GET_DETAIL:

      setDetail(action.detail);
      AccommodationStore.emitChange()
      break;

    default:
      // no
  }
})
export default AccommodationStore
