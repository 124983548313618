import React from "react"
import CalendarActions from '../actions/CalendarActions';
import PlanActions from '../actions/PlanActions';
import BookActions from '../actions/BookActions';
import BookFormActions from '../actions/BookFormActions';
import AccommodationActions from '../actions/AccommodationActions';

import Header from './parts/common/Header'
import Calendar from './parts/calendar/Calendar'
import CalendarSelector from './parts/calendar/CalendarSelector'
import BookNumSelector from './parts/common/BookNumSelector'
import StayNumSelector from './parts/common/StayNumSelector'
import  '../styles/calendar.css'
import {addDays} from  'date-fns'
import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class CalendarApp extends React.Component {

  constructor({match}){
    super()
    this.updateBookForm("days",1)
    this.updateCurrentMonth = this.updateCurrentMonth.bind(this)
    this.state = {
      accommodation_id:match.params.accommodation_id
    }

  }

  componentDidMount(){
    var date = new Date()
    var startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    var endDate = addDays(startDate,60)
    CalendarActions.setCurrentMonth(date.getFullYear(), date.getMonth() + 1)
    PlanActions.getList(this.state.accommodation_id,startDate,endDate)
    BookActions.getList(this.state.accommodation_id,startDate,endDate)
    AccommodationActions.getDetail(this.state.accommodation_id)
    BookFormActions.create()
    BookFormActions.update("person_number",2)
    //AuthStore.addChangeListener(this._onChange.bind(this))
  }

  componentWillUnmount(){
    //AuthStore.removeChangeListener(this._onChange.bind(this));
  }

  updateCurrentMonth(y,m){

    CalendarActions.setCurrentMonth(y, m)
    var startDate = new Date(y, m-1, 1);
    var endDate = addDays(startDate,60)

    BookActions.getList(this.state.accommodation_id,startDate,endDate)
  }

  updateBookForm(name,value){
    BookFormActions.update(name,value)
  }


  render(){
    return(
      <div>
        <Header accommodation_id={this.state.accommodation_id}/>
       
          <section id="book-num-select-box">
            <span>宿泊人数:&nbsp;</span>
            <BookNumSelector updateBookForm = {this.updateBookForm} />
            <span>&nbsp;人</span>

            <StayNumSelector updateBookForm = {this.updateBookForm} />
            <span>&nbsp;泊</span>


          </section>
          <section id="subtext"  dangerouslySetInnerHTML={{__html: config.get("NOTICE_" +this.state.accommodation_id )}}>
          </section>
          <section id="calendar">
          <h2>この宿の空室情報</h2>
          <CalendarSelector updateCurrentMonth={this.updateCurrentMonth}/>
          <Calendar accommodation_id ={this.state.accommodation_id} />
          </section>  
    
      </div>  

    )
  }
}
/** 
 * 
 * <section id="book-num-select-box">
          <span>宿泊人数:&nbsp;</span>
          <BookNumSelector updateBookForm = {this.updateBookForm} />
          <span>&nbsp;人</span>

          <StayNumSelector updateBookForm = {this.updateBookForm} />
          <span>&nbsp;泊</span>


        </section>
        <section id="calendar">
        <h2>この宿の空室情報</h2>
        <CalendarSelector updateCurrentMonth={this.updateCurrentMonth}/>
        <Calendar accommodation_id ={this.state.accommodation_id} />
        </section> **/