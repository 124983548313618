import AppDispatcher from "../dispatcher/AppDispatcher"
import { Record, List } from 'immutable'
import { EventEmitter } from "events"
import PlanConstants from "../constants/PlanConstants"
import assign from "object-assign"
import Plan from "../vo/Plan"
import Room from "../vo/Room"
import Schedule from "../vo/Schedule"

import { parseISO,addDays,subDays,isBefore,isAfter, isSameDay } from 'date-fns'
var CHANGE_EVENT = "change" // chenge evnetを定数にする

var _plan = null
var _plan_list = []
var _plan_start_date = null
var _plan_end_date = null
var _max_room_num = null
var _max_stay_num = null
/*
*  ここから処理本体を書き始める
*/


var setList= (list) =>{

  var _list = []
  var today = (new Date()).getTime()
  var _tmp_start_date = null
  _plan_start_date = null
  list.map((item) =>{
    var ps = PlanStore.createRecord(item)
    if(ps){
      _list.push(ps)

      if(today > item.start_at){
        _tmp_start_date = today + ((item.close_date_before) * 1000 * 60 * 60 * 24 ) + ((item.close_time_before) * 1000 * 60 * 60 )

      }else{
        _tmp_start_date = item.start_at
      }
      if(!_plan_start_date || _tmp_start_date < _plan_start_date){
        _plan_start_date = _tmp_start_date
      }
      if(!_plan_start_date || _tmp_start_date < _plan_start_date){
        _plan_start_date = _tmp_start_date
      }
      if(!_plan_end_date || _plan_end_date < item.end_at){
        _plan_end_date = item.end_at
      }

      //totalsytay max
      if(!_max_stay_num || _max_stay_num < item.room[0].max_num){
        _max_stay_num = item.room[0].max_num
      }

      if(!_max_room_num || _max_room_num < item.max_num){
        _max_room_num = item.max_num
      }
    }
  })
  _plan_list = _list
}

var setDetail = (item) =>{
  _plan = PlanStore.createRecord(item)
}

var PlanStore = assign({},EventEmitter.prototype,{


  getList(){
    return _plan_list
  },

  getDetail(){
    return _plan
  },

  getAvailablePaymentMethod(target_date){

    var method = []
    var today = new Date()
    if(_plan && _plan.payment_methods){
      for(var i = 0 ; i< _plan.payment_methods.length; i++){
        const item = _plan.payment_methods[i]
        if(item.limit_date == 0 || today.setDate(today.getDate() + item.limit_date) < target_date){
          method.push(item)
        }
      }
    }
    return method
  },

  getDetailFromList(id){
    for(var i = 0; i < _plan_list.length; i ++){
      if(_plan_list[i].id == id){
        return _plan_list[i]
      }
    }
    return null
  },

  getTargetPrice(target_date,unit){
    for(var i = 0; i < _plan.schedules.length; i++){
        const item = _plan.schedules[i]
        if(item.unit == unit && isSameDay(target_date , new Date(item.target_date))){
          return item.price
        }
    }
    return _plan.price
  },

  getTargetPriceWithPrice(plans,unit){

    for(var i = 0; i < plans.schedules.length; i++){
        const item = plans.schedules[i]
        if(item.unit === Number(unit)){
          return item.price
        }
    }
    return plans.price
  },

  getStartDate(){
    return _plan_start_date
  },

  getMinimumPlan(rooms, target_date, unit, person_number){

    const today = new Date()
    var min_plan = null
    var min_price = 0
    for(var r of rooms){
      for(var plan of r.plans){

        if(min_plan === null && !isBefore(subDays(target_date,plan.close_date_before),today )){
          min_plan = plan
          for(var c_s of min_plan.schedules)  {
            if(c_s.unit === person_number  && isSameDay(parseISO(c_s.target_date),target_date)){
              min_price = c_s.price
            }
          }
        } else if(min_plan){

          for(var s of plan.schedules){

            if(s.unit === unit){

              for(var a_s of plan.schedules)  {

                if(a_s.unit === person_number && min_price > a_s.price && !isBefore(subDays(target_date,plan.close_date_before),today )){
                  min_plan = plan
                  min_price = a_s.price
                }
              }
            }
          }
        }
      }
    }

    return min_plan
  },

  getEndDate(){
    return _plan_end_date
  },

  getMaxNum(){
    return _max_stay_num
  },
  getMaxStayNum(){
    return _max_room_num
  },

  createRecord(item){
    if(!item) return null
    var schedules = []

    for(var i = 0; i < item.schedules.length; i++){
      var schedule = item.schedules[i]
      schedules.push(new Schedule({
        id:schedule.id,
        unit:schedule.unit,
        target_date:schedule.target_date,
        price:schedule.price
      }))
    }

    var room = null
    if(item.room && item.room.length > 0){
      room = new Room({
        id:item.room[0].id,
        name:item.room[0].name,
        max_num:item.room[0].max_num
      })
    }

    return new Plan({
      id:item.id,
      name:item.name,
      close_date_before:item.close_date_before,
      close_time_before:item.close_time_before,
      price:item.price,
      start_at:item.start_at,
      end_at:item.end_at,
      max_num:item.max_num,
      check_in:item.check_in,
      check_out:item.check_out,
      description:item.description,
      additional_price:item.additional_price,
      schedules:schedules,
      room:room,
      unit:item.unit,
      payment_methods:item.payment_methods
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case PlanConstants.GET_PLAN_LIST:

      setList(action.list);
      PlanStore.emitChange()
      break;
    case PlanConstants.GET_PLAN_DETAIL:
      setDetail(action.item);
      PlanStore.emitChange()
      break;

    default:
      // no
  }
})
export default PlanStore
