import AppDispatcher from "../dispatcher/AppDispatcher"
import AccommodationConstants from "../constants/AccommodationConstants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import BookActions from "./BookActions"
import BookConstants from "../constants/BookConstants"

var OptionActions = {

  getList(accommodation_id,room_id){
    
    const room_id_str = room_id ? "?room_id=" + room_id : ""


    fetch(config.get("API2_PATH") + config.get("API2_ACCOMMODATION") +"/" +accommodation_id + "/options" + room_id_str, {
      method: 'GET'
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return null
      }else{
        return null
      }
    }).then(function(data) {
     
      if(data != null ){
        AppDispatcher.dispatch({
          actionType: AccommodationConstants.GET_OPTION_LIST,
          list: data
        })
      }
    })
  },

  /**
  POST    /books/:id/options                             controllers.OptionController.create(id:String)
PATCH   /books/:id/options/:option_id                  controllers.OptionController.update(id:String,option_id:String)
DELETE   /books/:id/options/:option_id                  controllers.OptionController.delete(id:String,option_id:String)
 */


  create(book_id, option_id, unit, days){

    fetch(config.get("API2_PATH") + config.get("API2_BOOK") + "/" + book_id + "/options", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'key': config.get("API2_KEY"),
        'id': config.get("API2_ID")
      },
      body: JSON.stringify({
        "option_id": option_id,
        "unit": Number(unit),
        "days": Number(days)
       })
     }).then(function(response) {
      if(response.status == 200){
         return response.json()
      }else{
        AppDispatcher.dispatch({
        actionType: BookConstants.ERROR_
        })
      }
    }).then(function(data) {
      console.log(data)
      if(data != null){
        BookActions.getDetail(book_id)
      }
    })
  },

  delete(book_id, option_id){
    fetch(config.get("API2_PATH") + config.get("API2_BOOK") + "/" + book_id + "/options/" + option_id, {
      method: 'DELETE',
      headers: {
  
        'key': config.get("API2_KEY"),
        'id': config.get("API2_ID")
      }
     }).then(function(response) {
      if(response.status == 204){
         return true
      }else{
        AppDispatcher.dispatch({
        actionType: BookConstants.ERROR_
        })
      }
    }).then(function(data) {
      if(data != null){
        BookActions.getDetail(book_id)
      }
    })
  }

}

export default OptionActions