import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import CalendarConstants from "../constants/CalendarConstants"
import assign from "object-assign"


var CHANGE_EVENT = "change" // chenge evnetを定数にする

var _current_month = null
var _current_year = null
/*
*  ここから処理本体を書き始める
*/


var setCarrentMonthAndYear= (year, month) =>{
  _current_month = month
  _current_year = year
}


var CalendarStore = assign({},EventEmitter.prototype,{


  getCurrentMonth(){
    return _current_month
  },

  getCurrentYear(){
    return _current_year
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case CalendarConstants.SET_CURRENT_YEAR_MONTH:
      setCarrentMonthAndYear(action.year, action.month);
      CalendarStore.emitChange()
      break;

    default:
      // no
  }
})
export default CalendarStore
