import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import BookConstants from "../constants/BookConstants"
import assign from "object-assign"
import BookForm from "../vo/BookForm"
import BookStore from "./BookStore"

var CHANGE_EVENT = "change" // chenge evnetを定数にする

var _book_form = new BookForm()

/*
*  ここから処理本体を書き始める
*/


var setDetail= (name,value) =>{
  _book_form =  _book_form.set(name,value)

}

var setOption = (value) =>{

  var item = null
  var is_find = false
  var options = [];
  for(var i = 0; i < _book_form.options.length; i++){
    item = _book_form.options[i]
    if(item.id == value.id){
      item = item.set("unit",value.unit)
      is_find = true
    }
    options.push(item)
  }

  _book_form = _book_form.set("option",options)

  if(!is_find){
    _book_form.options.push(value)
  }

}

var createDetail = () =>{
  _book_form = new BookForm()
}


var BookFormStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _book_form
  },




  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case BookConstants.UPDATE:
      setDetail(action.name,action.value);
      BookFormStore.emitChange()
      break;
    case BookConstants.ADD_OPTION:
      setOption(action.value);
      BookFormStore.emitChange()
      break;
    case BookConstants.CREATE:
      createDetail();
      BookFormStore.emitChange()
      break;

    default:


      // no
  }
})
export default BookFormStore
