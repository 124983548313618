import React from "react"
import config from 'react-global-configuration';
import CalendarStore from "../../../stores/CalendarStore"
import PlanStore from "../../../stores/PlanStore"
import BookStore from "../../../stores/BookStore"
import BookFormStore from "../../../stores/BookFormStore"
//import AuthActions from '../actions/AuthActions';
import { addDays,isBefore } from 'date-fns'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Calendar extends React.Component {

  constructor(){
    super()
    this.clickCalendar = this.clickCalendar.bind(this)
    this.CalendarStoreChangeHandler = this.CalendarStoreChangeHandler.bind(this)
    //this.PlanStoreChangeHandler = this.PlanStoreChangeHandler.bind(this)
    this.BookStoreChangeHandler = this.BookStoreChangeHandler.bind(this)
    this.BookFormStoreChangeHandler = this.BookFormStoreChangeHandler.bind(this)
    this.state = {
      year:null,
      month:null,
      plans:[],
      plan_start_date:null,
      plan_end_date:null,
      max_room_num:null,
      has_status:false,
      book_form:null
    }
  }

  componentDidMount(){
    CalendarStore.addChangeListener(this.CalendarStoreChangeHandler)
    //PlanStore.addChangeListener(this.PlanStoreChangeHandler)
    BookStore.addChangeListener(this.BookStoreChangeHandler)
    BookFormStore.addChangeListener(this.BookFormStoreChangeHandler)
  }

  componentWillUnmount(){
    CalendarStore.removeChangeListener(this.CalendarStoreChangeHandler)
    //PlanStore.removeChangeListener(this.PlanStoreChangeHandler)
    BookStore.removeChangeListener(this.BookStoreChangeHandler)
    BookFormStore.removeChangeListener(this.BookFormStoreChangeHandler)
  }

  CalendarStoreChangeHandler(){
    this.setState({
      year:CalendarStore.getCurrentYear(),
      month:CalendarStore.getCurrentMonth()
    })
  }
  /**
  PlanStoreChangeHandler(){
    this.setState({
      plans:PlanStore.getList(),
      plan_start_date:PlanStore.getStartDate(),
      plan_end_date:PlanStore.getEndDate(),
      max_room_num:PlanStore.getMaxNum()
    })
  }**/

  BookStoreChangeHandler(){

    this.setState({
      has_status:true
    })
  }

  BookFormStoreChangeHandler(){
    this.setState({
      book_form:BookFormStore.getDetail()
    })
  }

  clickCalendar(day,has_room){
    if(has_room)
      window.location.href = "/plan/" + this.props.accommodation_id + "/" + this.state.year + "/" + this.state.month + "/" + day  + "/" + this.state.book_form.person_number + "/" + this.state.book_form.days
  }

  check_available(sdt){
    var bookd_num = 0;
    var start_dt = new Date(sdt.getTime())
    for(var i = 0; i < this.state.book_form.days; i++){
      bookd_num = 0;
      if(BookStore.getBookStatus(start_dt.getTime())){
        bookd_num = BookStore.getBookStatus(start_dt.getTime()).booked_number
      }
      //checkROOM Status
      if(this.state.max_room_num - bookd_num <= 0 ) {
        return false
      }
      if(start_dt.getTime() < this.state.plan_start_date || start_dt.getTime() > this.state.plan_end_date  ) {
        return false
      }

      start_dt.setDate(start_dt.getDate() + 1)
    }

    return true
  }

  render(){

    if(!this.state.has_status) return null

    if(!this.state.book_form || !this.state.book_form.person_number) return null


    var theadhtml = null
    var tbodyhtml = null

    if(this.state.year){
      //まずはTitle作

      var head_html = []
      for(var i = 0; i < 7; i++){
        head_html.push(<td className={config.get("WEEK_CLASS_" + i)} key={"head_"+i}>{config.get("WEEK_" + i)}</td>)
      }
      theadhtml = <tr>{head_html}</tr>

      var start_dt = new Date(this.state.year, this.state.month - 1, 1);

      start_dt.setDate(start_dt.getDate() - start_dt.getDay() - 1);
      const end_dt = new Date(this.state.year, this.state.month, 0);
      end_dt.setDate(end_dt.getDate() - end_dt.getDay() + 6);
      const numOfWeeks = (end_dt - start_dt) / (24 * 3600 * 1000) / 7;

      const today = new Date()

      tbodyhtml  = Array.apply(null, {length: numOfWeeks}).map(item => {
        const td_items = [0,1,2,3,4,5,6].map(date => {
          start_dt = addDays(start_dt, 1)

          const schedule = BookStore.getBookStatus(start_dt)
          if(!schedule || start_dt.getMonth() !== this.state.month - 1){
            return <td>  <div className={"date no-room" } ></div></td>
          }

          //最安値を探す

          const min_plan = PlanStore.getMinimumPlan(schedule.room, start_dt, Number(this.state.book_form.days), this.state.book_form.person_number)
     
          if(!min_plan ){
            return <td>  <div className={"date no-room" } >{start_dt.getMonth() === this.state.month - 1 ? start_dt.getDate() : null}</div><div>{!isBefore(start_dt,today) && schedule.total_books > 0  ? <div className="available" >満室</div> : "-"}</div></td>
          }


          //const closed = subDays(start_dt, plan.)
          //宿泊日数分の空きチェック
          var _scs = []
          var _total_price = 0
          for(var _add_day = 0; _add_day < this.state.book_form.days; _add_day++){
            const _sc = (BookStore.getBookStatus(addDays(start_dt,_add_day)))
            if(!_sc || (_sc && _sc.room.length === 0  ) || (_sc && _sc.room.length > 0 && _sc.room[0].plans.length === 0 ) ){
              _scs.push(0)
            }
            else{
              _scs.push(_sc.total_rooms - _sc.total_books)
              const _min_plan = PlanStore.getMinimumPlan(BookStore.getBookStatus(addDays(start_dt,_add_day)).room, addDays(start_dt,_add_day), Number(this.state.book_form.days), Number(this.state.book_form.person_number))
              _total_price += PlanStore.getTargetPriceWithPrice(_min_plan, this.state.book_form.person_number)
            }
          }


          const has_room = _scs.indexOf(0) < 0 ? true : false

          const available_class = has_room ? "has-room" : "no-room"
          const less_room = has_room  ? <div className="is-pc roomnum less-num">残り{schedule.total_rooms - schedule.total_books}部屋</div> : null
          const day_str = start_dt.getDate()
          return  <td>
            {start_dt.getMonth() === this.state.month - 1 ? <div className={available_class} onClick={() => this.clickCalendar(day_str, has_room)}>
              <div className={"date  " + config.get("WEEK_CLASS_" + start_dt.getDay())} >{day_str}</div>
              <div className="available" >{!has_room ? "満室" :<span><span className="is-pc" >{this.state.book_form.days}泊で<br /></span>{_total_price.toLocaleString()}円〜</span>}</div>
              {less_room}
              </div>
            : null}
            </td>
        })
        return <tr>{td_items}</tr>
      })
      /** tbodyhtml = Array.apply(null, {length: numOfWeeks}).map(() => <tr >
                {[0,1,2,3,4,5,6].map(date => <td> {price_str}
                    {
                      (start_dt.setDate(start_dt.getDate() + 1),
                      (BookStore.getBookStatus(start_dt.getTime()) ? bookd_num = BookStore.getBookStatus(start_dt.getTime()).total_books : bookd_num = 0),
                      (BookStore.getBookStatus(start_dt.getTime()) && (BookStore.getBookStatus(start_dt.getTime()).price_min[this.state.book_form.person_number -1]) ? price_str = (BookStore.getBookStatus(start_dt.getTime()).price_min[this.state.book_form.person_number -1]).toLocaleString() + "円〜" : price_str = "-"),
                      ( start_dt.getTime() >= this.state.plan_start_date && start_dt.getTime() <= this.state.plan_end_date ? is_available = true :is_available = false),
                      //( is_available && this.state.max_room_num - bookd_num >0  ? has_room = true : has_room = false),
                      ( is_available && this.check_available(start_dt)  ? has_room = true : has_room = false),
                      ( has_room && this.state.max_room_num - bookd_num <= 3 ? availab_str = "残り"+ (this.state.max_room_num - bookd_num )+"部屋" : availab_str = "◯"),
                      ( has_room && this.state.max_room_num - bookd_num <= 3 ? small_num_class = "less-num"  : small_num_class = ""),


                      (start_dt.getMonth() === this.state.month - 1 ? <div onClick={this.clickCalendar} className={is_available && has_room ? "has-room" : "no-room"} day={start_dt.getDate()} room={is_available && has_room ? "1" : "0"}>{[<div className={"date " + config.get("WEEK_CLASS_" + date)} day={start_dt.getDate()} room={is_available && has_room ? "1" : "0"} >{start_dt.getDate()}</div>,
                        <div day={start_dt.getDate()} onClick={this.clickCalendar} room={is_available && has_room ? "1" : "0"} className="available">{!is_available || !has_room ? "-" :<span room={is_available && has_room ? "1" : "0"}  day={start_dt.getDate()}　><span className="is-pc" room={is_available && has_room ? "1" : "0"}　day={start_dt.getDate()}>1泊で<br /></span>{price_str}</span>}</div>,
                        <div day={start_dt.getDate()} onClick={this.clickCalendar} room={is_available && has_room ? "1" : "0"} className={"is-pc roomnum " + small_num_class}>{is_available && has_room ? availab_str : ""}{is_available && !has_room ? "満室" : ""}</div>]}</div>: null))
                    }
                </td>)}
            </tr>) **/


    }


    return(
      <table className="calendar full">
        <thead>{theadhtml}</thead>
        <tbody>
          {tbodyhtml}
        </tbody>
      </table>

    )
  }
}
