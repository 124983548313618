import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import BookConstants from "../constants/BookConstants"
import assign from "object-assign"
import BookStatus from "../vo/BookStatus"
import BookForm from "../vo/BookForm"
import {isSameDay} from  'date-fns'
var CHANGE_EVENT = "change" // chenge evnetを定数にする
var ERROR = "error"
var CREATED = "created" // chenge evnetを定数にする
var CANCEL = "cancel"

var _booked_list = []
var _book = null
var _book_usersecret = null
var _book_group_id = null

/*
*  ここから処理本体を書き始める
*/

var setBookDetail = (item) =>{
  _book = BookStore.createFormRecord(item)
}

var setList= (list) =>{
  _booked_list = list

}

var setCreatedBook = (_usersecret, _group_id) =>{

  _book_usersecret = _usersecret
  _book_group_id = _group_id
}


var BookStore = assign({},EventEmitter.prototype,{

  getList(){
    return _booked_list
  },

  getSchedule(room_id, plan_id, target_date, unit){

    for(var i in _booked_list){
      const schedule = _booked_list[i]
      if(isSameDay(new Date(schedule.target_date), target_date)){
        for(var r in schedule.room){
          const room = (schedule.room[r])
          if(room.id === room_id){
            for(var p in room.plans){
              const plan = room.plans[p]
              if(plan.id === plan_id){
                for(var s in plan.schedules){
                  const schedule = plan.schedules[s]
                  if(schedule.unit === Number(unit)){
                    return schedule
                  }
                }
              }
            }
          }
        }
      }
    }
    return null
  },

  getDetail(){

    return _book
  },
  getBookStatus(target_date){

    for(var i = 0 ; i < _booked_list.length; i ++){
      if(isSameDay(new Date(_booked_list[i].target_date),target_date)){
        return _booked_list[i]
      }
    }
    return null
  },

  getUserSecret(){
    return _book_usersecret
  },
  getGroupId(){
    return _book_group_id
  },


  createRecord(item){
    return new BookStatus({
      target_date:item.target_date,
      booked_number:item.booked_number,
      price_min:item.price_min
    })
  },

  createFormRecord(item){

    return new BookForm({
      id:item.id,
      accommodation_id:item.accommodation_id,
      target_date:item.start_date,
      name:item.name,
      price:item.price,
      mail:item.mail,
      plan_name:item.plan_name,
      room_name:item.room_name,
      days:item.days,
      status:item.status,
      plan_id:item.plan_id,
      payment_limit_date:item.payment_limit_date,
      payments:item.payments,
      options:item.options,
      payment_methods:item.payment_methods,
      delete_flg:item.delete_flg,
      uid:item.uid
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case BookConstants.GET_BOOKED_LIST:

      setList(action.list);
      BookStore.emitChange()
      break;
    case BookConstants.CREATED:
      setCreatedBook(action.usersecret,action.group_id);
      BookStore.emitChange(CREATED)
      break;
    case BookConstants.GET_BOOKED_DETAIL:
      setBookDetail(action.detail);
      BookStore.emitChange()
      break;
    case BookConstants.CANCEL:
        BookStore.emitChange(CANCEL)
        break;
    case BookConstants.ERROR_:
      BookStore.emitChange(ERROR)
      break;

    default:
      // no
  }
})
export default BookStore
